import * as actions from "./actions";

const userToken = JSON.parse(localStorage.getItem("user"));
const actualTime = new Date().getTime();

export const initialState = {
  isLogin: userToken && userToken.exp <= actualTime,
  loading: false,
  errorAuth: false,
  form: {
    hasError: false,
    username: "",
    password: "",
    password_backup: ""
  },
  formCreatePassword: {
    // Indicate if token has been used when create a new password
    usedToken: false,
    // Indicates if should redirect to login
    redirectToLogin: false
  }
};

const reducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case actions.AUTH_ON_LOADING:
      return {
        ...state,
        loading: true,
        errorAuth: false
      };
    case actions.AUTH_SUCCESS:
      return {
        ...state,
        isLogin: true,
        loading: false,
        errorAuth: false,
        form: { ...state.form, ...initialState.form },
        formCreatePassword: {
          ...state.formCreatePassword,
          usedToken: false,
          redirectToLogin: true
        }
      };

    case actions.AUTH_CLEAR:
      return {
        ...state,
        loading: false,
        isLogin: false,
        errorAuth: false,
        isAdmin: false
      };
    case actions.AUTH_ERROR:
      return {
        ...state,
        loading: false,
        form: { ...state.form, hasError: true },
        errorAuth: true,
        formCreatePassword: {
          ...state.formCreatePassword,
          usedToken: false,
          redirectToLogin: false
        }
      };
    default:
      return state;
  }
};

export { actions };
export default reducer;
