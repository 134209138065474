// imports
import apiClient, { clearSession, parseJwt } from "utils";
import { catchError } from "utils/httpError";

import { showNotification } from "utils/utilities";

// Constants
export const AUTH_ON_LOADING = "@@AUTH/AUTH_LOADING";
export const AUTH_ON_SUBMIT = "@@AUTH/AUTH_SUBMIT";
export const AUTH_SUCCESS = "@@AUTH/AUTH_SUCCESS";
export const AUTH_ADMIN_SUCCESS = "@@AUTH/AUTH_ADMIN_SUCCESS";
export const AUTH_CHANGED_PASSWORD = "@@AUTH/AUTH_CHANGED_PASSWORD";
export const AUTH_CLEAR = "@@AUTH/AUTH_CLEAR";
export const AUTH_ERROR = "@@AUTH/AUTH_ERROR";

export const AUTH_NOT_USED_TOKEN = "@@AUTH/AUTH_NOT_USED_TOKEN";
export const AUTH_USED_TOKEN = "@@AUTH/AUTH_USED_TOKEN";
export const AUTH_TOKEN_ALREADY_USED = "@@AUTH/AUTH_TOKEN_ALREADY_USED";

/**
 * Check if token has been used previously
 * @param {string} token, generated token
 */
export const onCheckToken = token => dispatch => {
  return apiClient({
    path: `login/password-reset-tokens/${token}`,
    method: "GET"
  })
    .then(json => {
      if (json.used_at) {
        return dispatch({
          type: AUTH_USED_TOKEN
        });
      } else {
        return dispatch({
          type: AUTH_NOT_USED_TOKEN
        });
      }
    })
    .catch(err =>
      dispatch({
        type: AUTH_USED_TOKEN,
        payload: err.message
      })
    );
};

export const onClearSession = () => dispatch => {
  clearSession();
  return dispatch({
    type: AUTH_CLEAR
  });
};

export const onSubmit = payload => dispatch => {
  dispatch({
    type: AUTH_ON_LOADING
  });
  return apiClient({
    path: "admin_username_check",
    method: "POST",
    body: payload
  })
    .then(json => {
      const id = String(json.user);

      if (json.code === 200) {
        const dataUser = {
          _password: payload._password,
          _id: id
        };
        apiClient({
          path: "admin_login_check",
          method: "POST",
          body: dataUser
        })
          .then(json => {
            const parsedToken = parseJwt(json.token);

            localStorage.setItem("user", JSON.stringify(parsedToken));
            localStorage.setItem("token", json.token);

            if (parsedToken.roles[0] === "ROLE_SUPERADMIN") {
              return dispatch({
                type: AUTH_SUCCESS
              });
            } else {
              showNotification(
                "Inicio de sesión",
                "El usuario no tiene permisos",
                "error"
              );
            }
          })
          .catch(err => {
            catchError(err, dispatch, "Inicio de sesión", AUTH_ERROR);
          });
      } else {
        showNotification(
          "Inicio de sesión",
          "El usuario no esta registrado",
          "error"
        );

        return dispatch({
          type: AUTH_ERROR,
          payload: json.message
        });
      }
    })
    .catch(err => {
      catchError(err, dispatch, "Inicio de sesión", AUTH_ERROR);
    });
};
