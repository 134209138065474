import * as actions from "./actions";

export const initialState = {
  data: {},
  users: [],
  results: 0
};

const reducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case actions.USER_GET:
      return {
        data: action.payload
      };

    case actions.USER_GET_LIST:
      return {
        users: action.payload.paginator,
        results: action.payload.results
      };

    default:
      return state;
  }
};

export { actions };
export default reducer;
