import React from "react";
import { Drawer as AntDrawer } from "antd";
import styled from "styled-components";

const StyledDrawer = styled(AntDrawer)`
  && {
    .ant-drawer-content-wrapper {
      height: 100% !important;
      .ant-drawer-content {
        height: auto;
        .ant-drawer-title {
          font-weight: bold;
          font-size: 20px;
          color: #484848;
        }
        .ant-drawer-body {
          max-height: calc(100vh - 55px);
          overflow: auto;
          min-height: 200px;
        }
      }
    }
  }
`;

const Drawer = ({ ...props }) => {
  return <StyledDrawer {...props} />;
};

export default Drawer;
