import React from "react";
import { version } from "../../package.json";

import { connect } from "react-redux";
import { Layout, Menu, Icon } from "antd";

import { Switch, Route, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";

import privatedRoutes from "routes/private";
import Logo from "components/Logo";

import { actions } from "core/Auth";

const { Header, Footer, Content } = Layout;

const NotFound = () => <div>not found</div>;

const switchRoutes = (
  <Switch>
    {privatedRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      if (prop.collapse)
        return prop.views.map((prop, key) => {
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        });
      const Component = prop.component;

      return (
        <Route
          exact
          path={prop.path}
          render={props => <Component {...props} />}
          key={key}
        />
      );
    })}
    <Route component={NotFound} />
  </Switch>
);
class Private extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //const { location, history } = this.props;
    const MenuItems = privatedRoutes
      .filter(route => !route.hideMenu)
      .map(i => (
        <Menu.Item key={i.path} disabled={i.disabled}>
          <Link to={i.path}>
            <Icon type={i.icon} />
            <span>{i.name}</span>
          </Link>
        </Menu.Item>
      ));

    return (
      <Layout>
        <Header
          className="header"
          style={{ position: "fixed", zIndex: 1, width: "100%" }}
        >
          <Logo />
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ lineHeight: "64px" }}
            selectedKeys={[this.props.location.pathname]}
          >
            {MenuItems}
            <Menu.Item
              style={{ float: "right" }}
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                this.props.onClearSession();
              }}
            >
              <Icon type="logout" />
              Cerrar Sesión
            </Menu.Item>
          </Menu>
        </Header>
        <Layout style={{ minHeight: "100vh", paddingTop: "65px" }}>
          <Layout>
            <Content style={{ margin: "0 16px" }}>{switchRoutes}</Content>
            <Footer style={{ textAlign: "center" }}>
              Opoque ©{new Date().getFullYear()} - {version}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

Private.propTypes = {};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  onClearSession: () => {
    dispatch(actions.onClearSession());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Private);
