import { combineReducers } from "redux";

/** import reducers */
import auth from "core/Auth";
import companies from "core/Companies";
import advertisers from "core/Advertisers";
import user from "core/User";

export default combineReducers({
  auth,
  companies,
  advertisers,
  user
});
