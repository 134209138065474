import { connect } from "react-redux";

import { Login as LoginView } from "views/Login";
import { actions } from "core/Auth";

const mapStateToProps = state => {
  return { isLogin: state.auth.isLogin };
};

const mapDispatchToProps = dispatch => ({
  onClearSession: () => dispatch(actions.onClearSession()),
  onLogin: payload => {
    dispatch(actions.onSubmit(payload));
  }
});

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginView);
