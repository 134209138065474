import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  isLogin,
  isAdmin,
  path,
  ...rest
}) => {
  return path ? (
    <Route
      {...rest}
      path={path}
      render={props => {
        if (isLogin) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  ) : null;
};

PrivateRoute.defaultProps = {
  isLogin: false,
  isAdmin: false
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  isLogin: PropTypes.bool,
  isAdmin: PropTypes.bool
};

export default PrivateRoute;
