import { connect } from "react-redux";

import { UsersList as View } from "views/UsersList";
import { getUsers } from "core/User/actions";

const mapStateToProps = state => ({
  users: state.user.users,
  results: state.user.results
});

const mapDispatchToProps = dispatch => ({
  getUsers: page => dispatch(getUsers(page))
});

export const UsersList = connect(mapStateToProps, mapDispatchToProps)(View);
