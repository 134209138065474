import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import Router from "routes";

// imports Polyfill
import "whatwg-fetch";
import { alternative as theme } from "theme";

import store from "./store";

const GlobalStyle = createGlobalStyle`
  *, *:before, *:after
  {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

  }
  *:not(.lnr) {
    font-family: ${props => props.theme.font.fontFamily};
  }
  html {
    font-size: 87.5%;
    height: 100%;    
    font-family: ${props => props.theme.font.fontFamily};
  }
  body {
  
    font-size: 1rem;
    width: 100%;
    height: 100%;
    
    margin: 0;
    padding: 0;
  }
  .ant-table-cell-fix-right {

      display:flex !important;
      justify-content:center !important;
    
  }

`;

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <Provider store={store}>
        <Router />
      </Provider>
    </>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
