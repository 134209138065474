import { notification } from "antd";
import { mergeObjectIE } from "utils/helpers";

export const mergeDeep = (target, source) => {
  const clonedTarget = mergeObjectIE({}, target);
  const clonedSource = mergeObjectIE({}, source);
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (let key of Object.keys(clonedSource)) {
    if (clonedSource[key] instanceof Object && key in clonedTarget)
      mergeObjectIE(
        clonedSource[key],
        mergeDeep(clonedTarget[key], clonedSource[key])
      );
  }

  // Join `clonedTarget` and modified `clonedSource`
  mergeObjectIE(clonedTarget || {}, clonedSource);
  return clonedTarget;
};

export const getExtension = fileName => {
  var re = /(?:\.([^.]+))?$/;

  return re.exec(fileName)[1];
};

export const infoStorybook = {
  inline: true,
  styles: {
    header: {
      h1: {
        color: "#ff6000"
      }
    }
  }
};

export function showNotification(message, description = "", type) {
  notification[type]({
    message,
    description,
    placement: "bottomRight"
  });
}
