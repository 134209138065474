// https://developer.mozilla.org/es/docs/Web/API/Fetch_API/Utilizando_Fetch
import axios from "axios";

/**
 * Remove token session
 */
export const clearSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

/**
 * Method to handle the response type
 * @param {Object} response
 */
const handleErrors = response => {
  let exception = {};
  if (response && response.status && response.status !== 200) {
    return response.json().then(data => {
      exception = data;
      throw exception;
    });
  }
  if (response.data.code && response.data.code !== 200) {
    exception = { code: response.data.code, message: response.data.data };
    throw exception;
  }
  return response;
};

/**
 * Function to realice request XHR
 * @param {*} options
 */

const apiClient = (options = {}) => {
  let url = process.env.REACT_APP_API_URL;

  url = options.path ? url.concat(options.path) : url;
  const optionsDefault = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json; charset=utf-8",
      ...(localStorage.getItem("token")
        ? { Authorization: `Bearer ${localStorage.getItem("token")}` }
        : {})
    }
  };

  options = { ...optionsDefault, ...options };

  if (options.body) {
    options.body = JSON.stringify(options.body);
  }
  return axios({
    url,
    method: options.method,
    data: options.body,
    headers: options.headers
  })
    .then(handleErrors)
    .then(response => {
      if (response.status === 200) {
        return response.data;
      }

      throw new Error(response);
    })
    .catch(err => {
      // the object comes from the Error object
      if (err.response) {
        if (err.response.status === 401) {
          throw new Error("Se ha caducado la sesion");
        } else if (err.response.status === 403) {
          throw new Error("No tienes permisos para realizar la acción");
        } else {
          const error =
            err.response.data && err.response.data.data
              ? err.response.data.data
              : err.response.data;
          throw new Error(error);
        }
      } else {
        throw new Error(JSON.stringify(err));
      }
      /*const error = err.response.data;
      let message = "";
      if (typeof error.message === "string") {
        message = error.message;
      }
      //return { code: 400, message };
      throw new Error(JSON.stringify({ code: err.response.status, message }));*/
    });
};

export default apiClient;
