/**
 * Calcula la desviacion estandar
 * @param {number} accumulate, valor acumulado
 * @param {number} iterations, numero de iteraciones
 * @param {number} average, nota media
 */

function getStandardDeviation(accumulate, iterations, average) {
  let divider = accumulate / iterations;
  divider = Math.abs(divider - Math.pow(average, 2));
  const deviation = Math.sqrt(divider);
  return {
    deviation,
    lower: average - deviation,
    upper: average + deviation
  };
}
function compare(a, b, key) {
  // Use toUpperCase() to ignore character casing
  const A = removeAccents(
    key
      .split(".")
      .reduce((o, i) => o[i], a)
      .toUpperCase()
  );
  const B = removeAccents(
    key
      .split(".")
      .reduce((o, i) => o[i], b)
      .toUpperCase()
  );

  let comparison = 0;
  if (A > B) {
    comparison = 1;
  } else if (A < B) {
    comparison = -1;
  }
  return comparison;
}

function removeAccents(str) {
  var accents =
    "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
  var accentsOut =
    "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  str = str.split("");
  var strLen = str.length;
  var i, x;
  for (i = 0; i < strLen; i++) {
    if ((x = accents.indexOf(str[i])) !== -1) {
      str[i] = accentsOut[x];
    }
  }
  return str.join("");
}

function getAverageQualification(value) {
  const sizeAnswers = value.num_answers;
  const sizeQuestions =
    value.num_correct_answers +
    value.num_fail_answers +
    value.num_neutral_answers;

  if (sizeAnswers === 0) {
    return 0;
  }

  const qualification =
    ((value.num_correct_answers - value.num_fail_answers * (1 / sizeAnswers)) /
      sizeQuestions) *
    10;
  return qualification;
}

/**
 * Suma todos los valores de los tests de un tema para un selector
 * @param {array} array, valor de todos los tests de un tema
 * @param {string} selector, puede ser last, average, best o worst
 */
function getAverageTestsQualification(array, selector) {
  let total = {
    num_correct_answers: 0,
    num_fail_answers: 0,
    num_neutral_answers: 0,
    num_answers: 0
  };

  // sumamos todas las columnas del test seleccionado
  array.forEach(test => {
    const selected = test[selector];
    total.num_correct_answers += selected.num_correct_answers;
    total.num_fail_answers += selected.num_fail_answers;
    total.num_neutral_answers += selected.num_neutral_answers;
    total.num_answers = 4; //+= selected.num_answers;
  });

  return getAverageQualification(total);
}

function getGlobalQualification(evaluations) {
  let acc = {
    num_correct_answers: 0,
    num_fail_answers: 0,
    num_neutral_answers: 0,
    num_answers: 0
  };
  evaluations.forEach(theme => {
    const tests = theme.tests;

    tests.forEach(test => {
      acc = {
        num_correct_answers:
          acc.num_correct_answers + test.last.num_correct_answers,
        num_fail_answers: acc.num_fail_answers + test.last.num_fail_answers,
        num_neutral_answers:
          acc.num_neutral_answers + test.last.num_neutral_answers,
        num_answers: 4 // acc.num_answers + test.last.num_answers
      };
    });
  });
  return getAverageQualification(acc);
}

function formatDateES(date) {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();
  let month = "";
  switch (date.getMonth()) {
    case 0:
      month = "Enero";
      break;
    case 1:
      month = "Febrero";
      break;
    case 2:
      month = "Marzo";
      break;
    case 3:
      month = "Abril";
      break;
    case 4:
      month = "Mayo";
      break;
    case 5:
      month = "Junio";
      break;
    case 6:
      month = "Julio";
      break;
    case 7:
      month = "Agosto";
      break;
    case 8:
      month = "Septiembre";
      break;
    case 9:
      month = "Octubre";
      break;
    case 10:
      month = "Noviembre";
      break;
    case 11:
    default:
      month = "Diciembre";
      break;
  }
  return `${day} ${month} ${year}`;
}

function parseDate(date) {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();
  let month = "";
  switch (date.getMonth()) {
    case 0:
      month = "01";
      break;
    case 1:
      month = "02";
      break;
    case 2:
      month = "03";
      break;
    case 3:
      month = "04";
      break;
    case 4:
      month = "05";
      break;
    case 5:
      month = "06";
      break;
    case 6:
      month = "07";
      break;
    case 7:
      month = "08";
      break;
    case 8:
      month = "09";
      break;
    case 9:
      month = "10";
      break;
    case 10:
      month = "11";
      break;
    case 11:
    default:
      month = "12";
      break;
  }
  return `${day}/${month}/${year}`;
}

function formatDate(date) {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();
  let month = "";
  switch (date.getMonth()) {
    case 0:
      month = "01";
      break;
    case 1:
      month = "02";
      break;
    case 2:
      month = "03";
      break;
    case 3:
      month = "04";
      break;
    case 4:
      month = "05";
      break;
    case 5:
      month = "06";
      break;
    case 6:
      month = "07";
      break;
    case 7:
      month = "08";
      break;
    case 8:
      month = "09";
      break;
    case 9:
      month = "10";
      break;
    case 10:
      month = "11";
      break;
    case 11:
    default:
      month = "12";
      break;
  }
  return `${year}-${month}-${day}`;
}

function parseTime(date) {
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  return `${hours}:${minutes}`;
}

function fancyTimeFormat(time) {
  // Hours, minutes and seconds
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

function fancyTimeSeconds(time) {
  // Hours, minutes and seconds
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";
  var seconds = "",
    minutes = "";

  seconds = secs === 1 ? `1 segundo` : `${secs} segundos`;
  minutes = mins === 1 ? `1 minuto` : `${mins} minutos`;

  if (mins === 0) {
    ret = seconds;
  } else {
    ret = `${minutes} y ${seconds}`;
  }

  return ret;
}

function mergeObjectIE(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];
    for (var key in source) {
      if (source.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }
  }
  return target;
}

export {
  mergeObjectIE,
  compare,
  formatDateES,
  formatDate,
  fancyTimeSeconds,
  fancyTimeFormat,
  parseDate,
  parseTime,
  getAverageQualification,
  getAverageTestsQualification,
  getGlobalQualification,
  getStandardDeviation
};
