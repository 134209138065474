import React, { useRef, useEffect, useState } from "react";
import { Input, Button, Icon, Divider, Popconfirm, Table } from "antd";
import styled from "styled-components";
import apiClient from "utils";
import ContainerTitle from "components/ContainerTitle";
import { showNotification } from "utils/utilities";
import { formatDateES, parseTime } from "utils/helpers";

const Delete = styled.div``;

const Wrapper = styled.div`
  padding: 1rem 1rem 0.5rem 1rem;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
  background-color: #ffffff;
  box-shadow: "0px 0px 12px -3px #ccc";
  max-width: 1800px;
`;

export const Advertisers = ({ getAdvertisers, list, results, history }) => {
  const [advertisers, setAdvertisers] = useState(list);
  const [page, setPage] = useState(1);
  useEffect(() => {
    getAdvertisers(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAdvertisers]);

  useEffect(() => {
    getAdvertisers(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setAdvertisers(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const inputRef = useRef(null);
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 50
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Tipo",
      dataIndex: "trainer",
      key: "trainer",
      render: text => {
        return text.name;
      }
    },
    {
      title: "Contraseña",
      dataIndex: "plain_password",
      key: "plain_password"
    },
    {
      title: "Opoque",
      dataIndex: "availability",
      key: "availability",
      render: (text, record) => {
        return text ? (
          <Button type="primary" shape="circle" icon="eye" />
        ) : (
          <Button type="danger" shape="circle" icon="eye-invisible" />
        );
      }
    },
    {
      title: "Verificado",
      dataIndex: "verified",
      key: "verified",
      render: (text, record) => {
        return text ? (
          <Button type="primary" shape="circle" icon="eye" />
        ) : (
          <Button type="danger" shape="circle" icon="eye-invisible" />
        );
      }
    },
    {
      title: "Activo",
      dataIndex: "active",
      key: "active",
      render: (text, record) => {
        return text ? (
          <Button type="primary" shape="circle" icon="eye" />
        ) : (
          <Button type="danger" shape="circle" icon="eye-invisible" />
        );
      }
    },
    {
      title: "Fecha Actualización",
      dataIndex: "update_date",
      key: "update_date",
      render: (text, record) => {
        return `${formatDateES(new Date(record.update_date))} - ${parseTime(
          new Date(record.update_date)
        )}`;
      }
    },
    {
      title: "Acciones",
      key: "actions",
      width: 113,
      fixed: "right",
      render: (text, record) => {
        return (
          <>
            <Button
              icon="eye"
              type="primary"
              onClick={() => history.push(`anunciante/${text.id}`)}
            />
            <Divider type="vertical" />
            <Popconfirm
              okText="Borrar"
              cancelText="Cancelar"
              placement="leftTop"
              onConfirm={() => {
                if (inputRef.current.state.value !== "eliminar") {
                  inputRef.current.state.value = "";
                  showNotification(
                    "Error",
                    "Debes introducir la palabra 'eliminar' para borrar",
                    "error"
                  );
                } else {
                  apiClient({
                    path: "super/advertiser",
                    method: "DELETE",
                    body: { id: record.id }
                  })
                    .then(json => {
                      showNotification(
                        "Borrado",
                        "Se ha borrado satisfactoriamente el anuncio",
                        "success"
                      );
                      setAdvertisers(
                        advertisers.filter(
                          advertiser => advertiser.id !== record.id
                        )
                      );
                    })
                    .catch(json => {
                      showNotification(
                        "Borrado",
                        "No se ha borrado el anuncio",
                        "error"
                      );
                    });
                }
              }}
              title={
                <Delete>
                  ¿Escribe la palabra 'eliminar' para borrar?
                  <Input size="large" allowClear ref={inputRef} />
                </Delete>
              }
              icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
            >
              <Button icon="delete" type="danger" />
            </Popconfirm>
          </>
        );
      }
    }
  ];

  return (
    <Wrapper>
      <ContainerTitle title="Listado de anunciantes" buttons={[]}>
        <Table
          scroll={{ x: "100%" }}
          rowKey={record => record.uid}
          columns={columns}
          dataSource={advertisers}
          pagination={{
            current: page,
            total: results,
            hideOnSinglePage: true
          }}
          onChange={pagination => {
            setPage(pagination.current);
          }}
        />
      </ContainerTitle>
    </Wrapper>
  );
};
