import { connect } from "react-redux";

import { Companies as View } from "views/Companies";
import { getCompanies, createCompany } from "core/Companies/actions";

const mapStateToProps = state => ({
  listReal: state.companies.listReal,
  listDemo: state.companies.listDemo
});

const mapDispatchToProps = dispatch => ({
  getCompanies: () => dispatch(getCompanies()),
  createCompany: data => dispatch(createCompany(data))
});

export const Companies = connect(mapStateToProps, mapDispatchToProps)(View);
