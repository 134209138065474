import React, { useEffect, useState } from "react";
import NewTable from "components/NewTable";
import {
  Popconfirm,
  Alert,
  Select,
  Typography,
  Row,
  Col,
  Form,
  Icon,
  Input,
  Button,
  Divider,
  Tag,
  Modal,
  message
} from "antd";

import ContainerTitle from "components/ContainerTitle";
import Loading from "components/Loading";
import { AddAdmin } from "./AddAdmin";

import { mainRoute } from "routes/private";
import apiClient from "utils";
import { compare, formatDateES, parseTime } from "utils/helpers";
import { showNotification } from "utils/utilities";
import { Link } from "react-router-dom";
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

const CompanyView = ({
  getCompany,
  setCompanyData,
  addNewAdmin,
  data,
  ...props
}) => {
  const [promotional, setPromotional] = useState({
    time: 0,
    percent: 0
  });
  const [users, setUsers] = useState({
    admin: [],
    pupils: [],
    validated: true,
    storage: 0,
    module_audio: false,
    module_video: false,
    admins_validated: true
  });
  const [check, checkModules] = useState(false);
  const [open, setOpen] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [towns, setTowns] = useState([]);
  const { id } = props.match.params;

  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
    setFieldsValue,
    validateFields
  } = props.form;

  useEffect(() => {
    getCompany(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!data && Object.keys(data).length) {
      validateFields();
      apiClient({
        path: `advertiser-data/combos`,
        method: "GET"
      }).then(json => {
        setProvinces(json.provinces.sort((a, b) => compare(a, b, "name")));
        if (data.city) {
          apiClient({
            path: `advertiser-data/town/${parseInt(data.city)}`,
            method: "GET"
          }).then(json => {
            setTowns(json.town.sort((a, b) => compare(a, b, "name")));
          });
        }
      });
      setPromotional({
        time: data.promotional_discount_time || data.discount_time,
        percent: data.promotional_discount_percent || data.discount_percent
      });
      setUsers({
        storage: parseInt(data.storage) / 1000000,
        module_video: data.moduleVideo ? data.moduleVideo.status : 0,
        module_audio: data.moduleAudio ? data.moduleAudio.status : 0,
        admins_validated: data.admins_validated,
        validated: data.validated,
        pupils: data.users
          .filter(
            user =>
              user.roles[0] === "ROLE_USER" ||
              user.roles[0] === "ROLE_USER_DEMO"
          )
          .map(user => ({
            id: user.id,
            name: user.name,
            last_name: user.last_name,
            email: user.email,
            last_access: user.last_access,
            registration: user.registration,
            removal: user.removal,
            active: user.active
          })),
        admin: data.users
          .filter(
            user =>
              user.roles[0] === "ROLE_ADMIN" ||
              user.roles[0] === "ROLE_ADMIN_DEMO" ||
              user.roles[0] === "ROLE_TEACHER" ||
              user.roles[0] === "ROLE_TEACHER_DEMO"
          )
          .map(user => ({
            id: user.id,
            name: user.name,
            role: user.roles[0],
            last_name: user.last_name,
            email: user.email,
            last_access: user.last_access,
            registration: user.registration,
            active: user.active
          }))
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validateSubdomain = () => {
    apiClient({
      path: `super/company/info/validate-subdomain`,
      method: "PUT",
      body: {
        id: data.id
      }
    })
      .then(json => {
        message.success("Dominio validado");
      })
      .catch(() => {
        message.error("Dominio no ha podido ser validado");
      });
  };

  const hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const values = props.form.getFieldsValue();
    if (check && (values.audio === 0 || values.video === 0)) {
      confirm({
        okText: "Si, estoy seguro",
        okType: "danger",
        cancelText: "No, lo revisaré",
        title: "AVISO IMPORTANTE",
        content:
          "Hay modulos que aparecen como deshabilitados, se eliminaran sus archivos. Deseas seguir guardando?",
        onOk() {
          setCompanyData(props.form.getFieldsValue(), id);
          checkModules(false);
        }
      });
    } else {
      setCompanyData(props.form.getFieldsValue(), id);
    }
  };
  const handleChange = (key, value) => {
    setFieldsValue({ [key]: value });
  };

  const nameError = isFieldTouched("name") && getFieldError("name");
  const phoneError = isFieldTouched("phone") && getFieldError("phone");
  const nifError = isFieldTouched("nif") && getFieldError("nif");
  const nifResponsibleError =
    isFieldTouched("nif_responsible") && getFieldError("nif_responsible");
  const emailError = isFieldTouched("email") && getFieldError("email");
  const streetError = isFieldTouched("street") && getFieldError("street");
  const storageError = isFieldTouched("storage") && getFieldError("storage");
  const postalCodeError =
    isFieldTouched("postalCode") && getFieldError("postalCode");
  const localityError = isFieldTouched("locality") && getFieldError("locality");
  const cityError = isFieldTouched("city") && getFieldError("city");

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 50
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      render: (a, b) => (b.role === "ROLE_ADMIN" ? "Administrador" : "Profesor")
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length
    },
    {
      title: "Apellidos",
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => a.last_name.length - b.last_name.length
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.length - b.email.length
    },
    {
      title: "Activo",
      dataIndex: "active",
      key: "active",
      sorter: (a, b) => a.active - b.active,
      render: (text, record) => <Text code>{record.active ? "SI" : "NO"}</Text>
    },
    {
      title: "Ultimo acceso",
      dataIndex: "last_access",
      key: "last_access",
      sorter: (a, b) =>
        new Date(a.last_access).getTime() - new Date(b.last_access).getTime(),
      render: (text, record) => {
        return record.last_access ? (
          <>
            {formatDateES(new Date(record.last_access)) +
              " - " +
              parseTime(new Date(record.last_access))}
          </>
        ) : (
          <>Sin registro</>
        );
      }
    },
    {
      title: "Registro",
      dataIndex: "registration",
      key: "registration",
      sorter: (a, b) =>
        new Date(a.registration).getTime() - new Date(b.registration).getTime(),
      render: (text, record) => {
        const date = new Date(record.registration);

        return (
          <>
            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
          </>
        );
      }
    }
  ];
  const columnsPupils = [
    ...columns,
    {
      title: "Fecha eliminación",
      dataIndex: "removal",
      key: "removal",
      sorter: (a, b) =>
        new Date(a.removal).getTime() - new Date(b.removal).getTime(),
      render: (text, record) => {
        const date = new Date(record.removal);
        return (
          <>
            {!!record.removal
              ? `${date.getDate()}/${date.getMonth() +
                  1}/${date.getFullYear()} ${
                  record.removal.split("T")[1].split("+")[0]
                }`
              : ""}
          </>
        );
      }
    }
  ];

  const columsAdmins = [
    ...columns,
    {
      title: "Acciones",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Button
            icon="edit"
            type="primary"
            onClick={() =>
              props.history.push(`${mainRoute}/academias/user/${record.id}`)
            }
          >
            Editar
          </Button>
        </>
      )
    }
  ];

  const changeCity = city => {
    apiClient({
      path: `advertiser-data/town/${city}`,
      method: "GET"
    }).then(json => {
      setTowns(json.town.sort((a, b) => compare(a, b, "name")));
    });

    handleChange("city", city);
    handleChange("locality", undefined);
  };

  const confirmValidationCompany = async () => {
    await setCompanyData(props.form.getFieldsValue(), id);
    return apiClient({
      path: `super/validate_company/${id}`,
      method: "PUT"
    })
      .then(json => {
        showNotification(
          "Validar administradores",
          "Se ha validado la academia y hemos enviado el contrato",
          "success"
        );
        setUsers({ ...users, validated: true });
      })
      .catch(err => showNotification("Error", err.message, "error"));
  };

  const confirmValidationAdminsAuto = () => {
    return apiClient({
      path: `super/validate_admins_auto/${id}`,
      method: "PUT"
    })
      .then(json => {
        showNotification(
          "Validar profesores",
          "Se han validado los profesores",
          "success"
        );
        setUsers({ ...users, validated: true, admins_validated: true });
      })
      .catch(err => showNotification("Error", err.message, "error"));
  };

  const verifyEmailAws = () => {
    apiClient({
      path: `super/company/info/validate-aws-email`,
      method: "PUT",
      body: {
        id: data.id
      }
    })
      .then(json => {
        message.success("Email verificado");
      })
      .catch(() => {
        message.error("El email no ha podido ser validado");
      });
  };

  const confirmValidationAdminsHandle = () => {
    return apiClient({
      path: `super/validate_admins_handle/${id}`,
      method: "PUT"
    })
      .then(json => {
        showNotification(
          "Validar profesores",
          "Se han validado los profesores",
          "success"
        );
        setUsers({ ...users, validated: true, admins_validated: true });
      })
      .catch(err => showNotification("Error", err.message, "error"));
  };

  const onChangePhone = event => {
    const text = event.target.value;

    if (text.length > 15) {
      return text.substr(0, 15);
    }
    return text;
  };

  const onChangePostalCode = event => {
    const text = event.target.value;

    if (text.length > 5) {
      return text.substr(0, 5);
    }
    return text;
  };

  return !!data && Object.keys(data).length ? (
    <>
      {!users.admins_validated && users.validated && (
        <ContainerTitle
          title="Acciones sobre los administradores"
          buttons={[
            users.validated && (
              <Popconfirm
                title="Activarás todos los administradores, enviando un email con datos de acceso"
                onConfirm={confirmValidationAdminsHandle}
                okText="Si, estoy de acuerdo"
                cancelText="No"
              >
                <Button type="primary" icon="like">
                  Activar Administradores Manualmente
                </Button>
              </Popconfirm>
            )
            // users.validated && (
            //   <Popconfirm
            //     title="Se modificará la fecha de activación y se enviará un email con datos de acceso el día seleccionado"
            //     onConfirm={confirmValidationAdminsAuto}
            //     okText="Si, estoy de acuerdo"
            //     cancelText="No"
            //   >
            //     <Button
            //       type="danger"
            //       style={{ background: "red", color: "white" }}
            //       icon="exclamation-circle"
            //     >
            //       Activar todos los Administradores Automáticamente
            //     </Button>
            //   </Popconfirm>
            // )
          ]}
        >
          &nbsp;
        </ContainerTitle>
      )}

      <AddAdmin
        onAddAdmin={data => addNewAdmin(data, id)}
        open={open}
        closeDrawer={() => setOpen(false)}
      />
      <ContainerTitle
        title={
          <>
            <Text code>{data.id}</Text>
            {data.name}
          </>
        }
        buttons={[
          <Link to={`/dashboard/academias/${id}/facturacion`}>
            <Button icon="arrow-left">Ir a facturacion </Button>
          </Link>
        ]}
      >
        <Form onSubmit={handleSubmit}>
          {!users.validated && (
            <>
              <Row type="flex" justify="center">
                <Col>
                  {" "}
                  <Alert
                    message="AVISO"
                    description="Esta academia/preparador aún no ha sido validada."
                    type="warning"
                    showIcon
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center">
                <Col>
                  <Popconfirm
                    title="¿Has comprobado que esté todo bien antes de validar?"
                    onConfirm={confirmValidationCompany}
                    okText="Si, validar"
                    cancelText="No"
                  >
                    <Button
                      style={{ marginTop: "20px" }}
                      type="primary"
                      icon="info-circle"
                    >
                      Validar la academia/preparador
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
              <Divider />
            </>
          )}
          <Row
            type="flex"
            justify="center"
            align="middle"
            gutter={16}
            style={{ textAlign: "center" }}
          >
            <Col xs={24} md={8}>
              <Icon type="audio" theme="filled" />
              <h3>Módulo de audio</h3>
              <h5>
                {" "}
                <Tag color="red">
                  AVISO: Si se deshabilita, se borran los archivos
                </Tag>
              </h5>
              <Form.Item>
                {getFieldDecorator("audio", {
                  initialValue: users.module_audio,
                  rules: []
                })(
                  <Select
                    onChange={value => value === 0 && checkModules(true)}
                    style={{ width: 175 }}
                    placeholder="Modulo Audio"
                  >
                    <Option value={0}>Deshabilitado</Option>
                    <Option value={1}>Habilitado</Option>
                    <Option value={2}>Suspendido</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Icon type="video-camera" theme="filled" />
              <h3>Módulo de video</h3>
              <h5>
                {" "}
                <Tag color="red">
                  AVISO: Si se deshabilita, se borran los archivos
                </Tag>
              </h5>
              <Form.Item>
                {getFieldDecorator("video", {
                  initialValue: users.module_video,
                  rules: []
                })(
                  <Select
                    onChange={value => value === 0 && checkModules(true)}
                    style={{ width: 175 }}
                    placeholder="Modulo Video"
                  >
                    <Option value={0}>Deshabilitado</Option>
                    <Option value={1}>Habilitado</Option>
                    <Option value={2}>Suspendido</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Divider style={{ height: "100px" }} type="vertical" />
            {users.storage && (
              <Col xs={24} md={7}>
                <Form.Item
                  label="Almacenamiento disponible para la academia"
                  validateStatus={storageError ? "error" : ""}
                  help={storageError || ""}
                >
                  {getFieldDecorator("storage", {
                    initialValue: users.storage,
                    rules: [
                      {
                        required: true,
                        message: "Introduce un número válido en GB!"
                      }
                    ]
                  })(
                    <Select
                      style={{ width: 150 }}
                      // onChange={e => handleChange("storage", e)}
                      placeholder="Almacenamiento"
                    >
                      <Option value={10000}>10 GB</Option>
                      <Option value={30000}>30 GB</Option>
                      <Option value={60000}>60 GB</Option>
                      <Option value={120000}>120 GB</Option>
                      <Option value={200000}>200 GB</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>

          {data.subdomain && data.subdomain.status === 0 && (
            <>
              <Divider />
              <Row type="flex">
                <Col xs={24}>
                  <h3 style={{ color: "red", fontWeight: "bold" }}>
                    Este usuario ha solicitado un{" "}
                    {data.subdomain.type !== "domain"
                      ? "subdominio"
                      : "dominio"}
                  </h3>
                </Col>
                <Col xs={24} md={8}>
                  <b>
                    Nombre del{" "}
                    {data.subdomain.type !== "domain"
                      ? "subdominio"
                      : "dominio"}
                    :{" "}
                  </b>
                  {data.subdomain.name}
                </Col>
                <Col xs={24} md={8}>
                  <b>Fecha de solicitud: </b>
                  {formatDateES(new Date(data.subdomain.request))}
                </Col>
                <Col xs={24} md={8}>
                  <Button onClick={validateSubdomain} type="primary">
                    Validar{" "}
                    {data.subdomain.type !== "domain"
                      ? "subdominio"
                      : "dominio"}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          <Divider />
          <Row gutter={16}>
            <Col md={24} lg={4}>
              <Form.Item
                label="NIF"
                validateStatus={nifError ? "error" : ""}
                help={nifError || ""}
              >
                {getFieldDecorator("nif", {
                  initialValue: data._n_i_f,
                  rules: [
                    {
                      required: true,
                      message: "Introduce un NIF válido!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon
                        type="idcard"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    onChange={e => handleChange("_n_i_f", e.target.value)}
                    placeholder="NIF"
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={8}>
              <Form.Item
                label="Nombre de Academia"
                validateStatus={nameError ? "error" : ""}
                help={nameError || ""}
              >
                {getFieldDecorator("name", {
                  initialValue: data.name,
                  rules: [
                    {
                      required: true,
                      message: "Introduce un nombre de academia!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={e => handleChange("name", e.target.value)}
                    placeholder="Nombre de academia"
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={4}>
              <Form.Item
                label="Teléfono"
                validateStatus={phoneError ? "error" : ""}
                help={phoneError || ""}
              >
                {getFieldDecorator("phone", {
                  getValueFromEvent: onChangePhone,
                  initialValue: data.phone,
                  rules: [
                    {
                      required: true,
                      message: "Introduce un telefono!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="number"
                    onChange={e => handleChange("phone", e.target.value)}
                    placeholder="Teléfono de academia"
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={8}>
              <Form.Item
                label="Email de contacto"
                validateStatus={emailError ? "error" : ""}
                help={emailError || ""}
              >
                {getFieldDecorator("email", {
                  initialValue: data.email,
                  rules: [
                    {
                      required: true,
                      message: "Introduce un email!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={e => handleChange("email", e.target.value)}
                    placeholder="Email"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} lg={10}>
              <Form.Item
                label="Calle"
                validateStatus={streetError ? "error" : ""}
                help={streetError || ""}
              >
                {getFieldDecorator("street", {
                  initialValue: data._street,
                  rules: [
                    {
                      required: true,
                      message: "Introduce una calle válida!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={e => handleChange("street", e.target.value)}
                    placeholder="Calle"
                  />
                )}
              </Form.Item>
            </Col>

            <Col md={24} lg={3}>
              <Form.Item
                label="Código Postal"
                validateStatus={postalCodeError ? "error" : ""}
                help={postalCodeError || ""}
              >
                {getFieldDecorator("postalCode", {
                  getValueFromEvent: onChangePostalCode,
                  initialValue: data._postal_code,
                  rules: [
                    {
                      required: true,
                      message: "Introduce un código postal válido!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="number"
                    onChange={e => handleChange("_postal_code", e.target.value)}
                    placeholder="Cod. Postal"
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={6}>
              <Form.Item
                label="Provincia"
                validateStatus={cityError ? "error" : ""}
                help={cityError || ""}
              >
                {getFieldDecorator("city", {
                  initialValue:
                    (data.city !== "" && parseInt(data.city)) || undefined,
                  rules: [
                    {
                      required: true,
                      message: "Introduce una provincia válida!"
                    }
                  ]
                })(
                  <Select
                    style={{ width: "100%" }}
                    onChange={e => changeCity(e)}
                    placeholder="Selecciona una provincia"
                  >
                    {provinces.map(province => (
                      <Option value={province.id}>{province.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={5}>
              <Form.Item
                label="Localidad"
                validateStatus={localityError ? "error" : ""}
                help={localityError || ""}
              >
                {getFieldDecorator("locality", {
                  initialValue:
                    (data.locality !== "" && parseInt(data.locality)) ||
                    undefined,
                  rules: [
                    {
                      required: true,
                      message: "Introduce una localidad válida!"
                    }
                  ]
                })(
                  <Select
                    style={{ width: "100%" }}
                    onChange={e => handleChange("locality", e)}
                    placeholder="Selecciona una localidad"
                  >
                    {towns.map(town => (
                      <Option value={town.id}>{town.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          {data.responsible && (
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item label="Nombre de contacto">
                  {getFieldDecorator("responsible", {
                    initialValue: data.responsible,
                    rules: [
                      {
                        required: true,
                        message: "Introduce un nombre válido!"
                      }
                    ]
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="edit"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      onChange={e =>
                        handleChange("responsible", e.target.value)
                      }
                      placeholder="Nombre de contacto"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="NIF Responsable"
                  validateStatus={nifResponsibleError ? "error" : ""}
                  help={nifResponsibleError || ""}
                >
                  {getFieldDecorator("nif_responsible", {
                    initialValue: data.nif_responsible,
                    rules: []
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="edit"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      onChange={e =>
                        handleChange("nif_responsible", e.target.value)
                      }
                      placeholder="Nif responsable"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}
          <Divider />
          {data.contact_message && (
            <Row justify="end" type="flex">
              <Col span={24}>
                <Form.Item label="Mensaje de contacto">
                  {getFieldDecorator("contact_message", {
                    initialValue: data.contact_message
                  })(<TextArea autoSize disabled />)}
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={16} justify="end" type="flex">
            <Col span={8}>
              <Form.Item label="Código Promocional">
                {getFieldDecorator("coupon_code", {
                  initialValue: data.coupon_code
                })(<Input disabled />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Tiempo aplicado">
                {getFieldDecorator("promotional_discount_time", {
                  initialValue: promotional.time
                })(
                  <Select
                    onChange={value =>
                      setPromotional({
                        ...promotional,
                        time: value
                      })
                    }
                  >
                    <Option value={0}>Sin descuento</Option>
                    <Option value={1}>1 mes</Option>
                    <Option value={2}>2 meses</Option>
                    <Option value={3}>3 meses</Option>
                    <Option value={4}>4 meses</Option>
                    <Option value={5}>5 meses</Option>
                    <Option value={6}>6 meses</Option>
                    <Option value={7}>7 meses</Option>
                    <Option value={8}>8 meses</Option>
                    <Option value={9}>9 meses</Option>
                    <Option value={10}>10 meses</Option>
                    <Option value={11}>11 meses</Option>
                    <Option value={12}>12 meses</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Porcentaje de descuento">
                {getFieldDecorator("promotional_discount_percent", {
                  initialValue: promotional.percent
                })(
                  <Select
                    onChange={value =>
                      setPromotional({
                        ...promotional,
                        percent: value
                      })
                    }
                  >
                    {Array.from(Array(101).keys()).map(index => (
                      <Option value={index}>{index} %</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end" type="flex" gutter={16}>
            <Col>
              <Form.Item>
                <Button
                  type="danger"
                  disabled={data._a_w_s_verified}
                  onClick={() => verifyEmailAws()}
                >
                  Verificar Email con AWS
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors(getFieldsError())}
                >
                  Guardar los datos
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider />
      </ContainerTitle>
      <NewTable
        tableTitle="Listado de Administradores/Profesores"
        tableSubTitle=""
        buttonsHeader={[
          <Button onClick={() => setOpen(true)} type="dashed" icon="plus">
            Añadir Administrador
          </Button>
        ]}
        header={""}
        iconTitle={null}
        columns={columsAdmins}
        data={users.admin}
      />
      <NewTable
        tableTitle="Listado de Alumnos"
        tableSubTitle=""
        buttonsHeader={[]}
        header={""}
        iconTitle={null}
        columns={columnsPupils}
        data={users.pupils}
      />
    </>
  ) : (
    <Loading />
  );
};

export const Company = Form.create({ name: "company_form" })(CompanyView);
