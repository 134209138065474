import { connect } from "react-redux";

import { Advertisers as View } from "views/Advertisers";
import { getAdvertisers } from "core/Advertisers/actions";

const mapStateToProps = state => ({
  list: state.advertisers.list,
  results: state.advertisers.results,
  maxPages: state.advertisers.maxPages
});

const mapDispatchToProps = dispatch => ({
  getAdvertisers: page => dispatch(getAdvertisers(page))
});

export const Advertisers = connect(
  mapStateToProps,
  mapDispatchToProps
)(View);
