// imports
import apiClient from "utils";

import { showNotification } from "utils/utilities";

// Constants
export const ADVERTISERS_GET = "@@ADVERTISERS/ADVERTISERS_GET";
export const ADVERTISER_GET = "@@ADVERTISERS/ADVERTISER_GET";

export const getAdvertisers = page => dispatch => {
  return apiClient({
    path: `super/advertisers?page=${page}`,
    method: "GET"
  })
    .then(json => {
      return dispatch({
        type: ADVERTISERS_GET,
        payload: json.data.paginator,
        results: json.data.results
      });
    })
    .catch(err => showNotification("Error", err.message, "error"));
};

export const getAdvertiser = id => dispatch => {
  return apiClient({
    path: `super/advertiser/${id}`,
    method: "GET"
  })
    .then(json => {
      return dispatch({
        type: ADVERTISER_GET,
        payload: json.data
      });
    })
    .catch(err => showNotification("Error", err.message, "error"));
};

export const setCompanyData = (data, id) => dispatch => {
  const payload = {
    verified: data.verified
  };
  return apiClient({
    path: `v1/advertiser/${id}`,
    method: "PUT",
    body: payload
  })
    .then(json => {
      showNotification(
        "Modificar anuncio",
        "Se ha modificado el anuncio",
        "success"
      );
    })
    .catch(err => showNotification("Error", err.message, "error"));
};
