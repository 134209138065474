import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import NewTable from "components/NewTable";
import {
  Tabs,
  Select,
  Popconfirm,
  Row,
  Col,
  Form,
  Icon,
  Input,
  Divider,
  Button
} from "antd";
import Drawer from "components/Drawer";

import { mainRoute } from "routes/private";
import apiClient from "utils";
import { showNotification } from "utils/utilities";
import { compare } from "utils/helpers";
const { TabPane } = Tabs;

const Delete = styled.div``;
const { Option } = Select;
const CompaniesView = ({
  getCompanies,
  createCompany,
  listReal,
  listDemo,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [towns, setTowns] = useState([]);
  const [companies, setCompanies] = useState(listReal);
  const [realCompanies, setCompaniesReal] = useState(listReal);
  const [demoCompanies, setCompaniesDemo] = useState(listDemo);

  const inputRef = useRef(null);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    validateFields();
    apiClient({
      path: `advertiser-data/combos`,
      method: "GET"
    }).then(json => {
      setProvinces(json.provinces.sort((a, b) => compare(a, b, "name")));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCompaniesReal(listReal);
  }, [listReal]);
  useEffect(() => {
    setCompaniesDemo(listDemo);
  }, [listDemo]);

  const hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
    setFieldsValue,
    validateFields,
    resetFields
  } = props.form;

  const handleSubmit = e => {
    e.preventDefault();
    createCompany(props.form.getFieldsValue());
    resetFields();
    setOpen(false);
  };
  const handleChange = (key, value) => {
    setFieldsValue({ [key]: value });
  };

  const blockCompany = id => {
    apiClient({
      path: "super/block",
      method: "POST",
      body: { id }
    })
      .then(json => {
        const list = [...companies];
        list.map(company => {
          if (company.id === id) {
            company.active = false;
          }
          return company;
        });
        setCompanies(list);
        showNotification(
          "Bloquear academia",
          "La academia ha sido bloqueada",
          "success"
        );
      })
      .catch(err => {
        showNotification(
          "Error",
          "No se ha podido bloquear la academia",
          "error"
        );
      });
  };

  const activeCompany = id => {
    apiClient({
      path: "super/active",
      method: "POST",
      body: { id }
    })
      .then(json => {
        const list = [...companies];
        list.map(company => {
          if (company.id === id) {
            company.active = true;
          }
          return company;
        });
        setCompanies(list);
        showNotification(
          "Activar academia",
          "La academia ha sido activada",
          "success"
        );
      })
      .catch(err => {
        showNotification(
          "Error",
          "No se ha podido activar la academia",
          "error"
        );
      });
  };

  const nameError = isFieldTouched("name") && getFieldError("name");
  const phoneError = isFieldTouched("phone") && getFieldError("phone");
  const nifError = isFieldTouched("nif") && getFieldError("nif");
  const storageError = isFieldTouched("storage") && getFieldError("storage");
  const emailError = isFieldTouched("email") && getFieldError("email");
  const streetError = isFieldTouched("street") && getFieldError("street");

  const postalCodeError =
    isFieldTouched("postalCode") && getFieldError("postalCode");
  const localityError = isFieldTouched("locality") && getFieldError("locality");
  const cityError = isFieldTouched("city") && getFieldError("city");

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 50
    },
    {
      title: "Tipo",
      dataIndex: "type_user",
      key: "type_user",
      width: 150,
      render: (text, record) => {
        if (text === "admin") {
          return "Real";
        } else {
          return "Demo";
        }
      }
    },
    { title: "NIF", dataIndex: "_n_i_f", key: "_n_i_f", width: 150 },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      key: "phone"
    },
    {
      title: "Almacenamiento Contratado",
      dataIndex: "storage",
      key: "storage",
      render: (text, record) => {
        return `${parseInt(text) / 1000000} MB`;
      }
    },
    {
      title: "Acciones",
      key: "actions",
      fixed: "right",
      render: (text, record) => {
        return (
          <>
            {record.validated ? (
              <>
                <Button
                  icon="eye"
                  type="primary"
                  title="detalles"
                  onClick={() =>
                    props.history.push(`${mainRoute}/academias/${record.id}`)
                  }
                />
                <Divider type="vertical" />
                <Button
                  icon="credit-card"
                  type="dashed"
                  title="facturacion"
                  onClick={() =>
                    props.history.push(
                      `${mainRoute}/academias/${record.id}/facturacion`
                    )
                  }
                />
                <Divider type="vertical" />
              </>
            ) : (
              <>
                <Button
                  icon="exclamation-circle"
                  type="danger"
                  onClick={() =>
                    props.history.push(`${mainRoute}/academias/${record.id}`)
                  }
                >
                  Validar
                </Button>

                <Divider type="vertical" />
              </>
            )}

            {record.validated &&
              (record.active ? (
                <>
                  <Button
                    onClick={() => blockCompany(record.id)}
                    icon="lock"
                    title="bloquear"
                    type="danger"
                  />
                  <Divider type="vertical" />
                </>
              ) : (
                <>
                  <Button
                    onClick={() => activeCompany(record.id)}
                    icon="unlock"
                    title="desbloquear"
                    type="primary"
                  />
                  <Divider type="vertical" />
                </>
              ))}

            <Popconfirm
              okText="Borrar"
              cancelText="Cancelar"
              placement="leftTop"
              onConfirm={() => {
                if (inputRef.current.state.value !== "eliminar") {
                  inputRef.current.state.value = "";
                  showNotification(
                    "Error",
                    "Debes introducir la palabra 'eliminar' para borrar",
                    "error"
                  );
                } else {
                  setLoading(true);
                  apiClient({
                    path: "super/company",
                    method: "DELETE",
                    body: { id: record.id }
                  })
                    .then(json => {
                      setLoading(false);
                      getCompanies();
                      showNotification(
                        "Borrado",
                        "Se ha borrado satisfactoriamente la academia",
                        "success"
                      );
                    })
                    .catch(json => {
                      setLoading(false);
                      showNotification(
                        "Borrado",
                        "No se ha borrado la academia",
                        "error"
                      );
                    });
                }
              }}
              title={
                <Delete>
                  ¿Escribe la palabra 'eliminar' para borrar?
                  <Input size="large" allowClear ref={inputRef} />
                </Delete>
              }
              icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
            >
              <Button
                loading={loading}
                title="eliminar"
                icon="delete"
                type="danger"
              />
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const changeCity = city => {
    apiClient({
      path: `advertiser-data/town/${city}`,
      method: "GET"
    }).then(json => {
      setTowns(json.town.sort((a, b) => compare(a, b, "name")));
    });

    handleChange("city", city);
  };
  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Reales" key="1">
          <NewTable
            tableTitle="Empresas Reales"
            tableSubTitle=""
            buttonsHeader={[]}
            header={""}
            iconTitle={null}
            columns={columns}
            data={realCompanies}
          />
        </TabPane>
        <TabPane tab="Demo" key="2">
          <NewTable
            tableTitle="Empresas Demo"
            tableSubTitle=""
            buttonsHeader={[]}
            header={""}
            iconTitle={null}
            columns={columns}
            data={demoCompanies}
          />
        </TabPane>
      </Tabs>

      <Drawer
        title="Crear nueva academia"
        placement="top"
        closable={true}
        onClose={() => {
          setOpen(false);
        }}
        visible={open}
      >
        <Form onSubmit={handleSubmit}>
          <Row
            type="flex"
            justify="center"
            gutter={16}
            style={{ textAlign: "center" }}
          >
            <Col md={9}>
              <Form.Item
                label="Almacenamiento disponible para la academia en GB"
                validateStatus={storageError ? "error" : ""}
                help={storageError || ""}
              >
                {getFieldDecorator("storage", {
                  initialValue: "30000",
                  rules: [
                    {
                      required: true,
                      message: "Introduce un número válido en GB!"
                    }
                  ]
                })(
                  <Select
                    style={{ width: 150 }}
                    onChange={e => handleChange("storage", e)}
                    placeholder="Almacenamiento en GB"
                  >
                    <Option value="30000">30 GB</Option>
                    <Option value="60000">60 GB</Option>
                    <Option value="120000">120 GB</Option>
                    <Option value="200000">200 GB</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col md={24} lg={4}>
              <Form.Item
                label="NIF"
                validateStatus={nifError ? "error" : ""}
                help={nifError || ""}
              >
                {getFieldDecorator("_n_i_f", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: "Introduce un NIF válido!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon
                        type="idcard"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    onChange={e => handleChange("_n_i_f", e.target.value)}
                    placeholder="NIF"
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={8}>
              <Form.Item
                label="Nombre de Academia"
                validateStatus={nameError ? "error" : ""}
                help={nameError || ""}
              >
                {getFieldDecorator("name", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: "Introduce un nombre de academia!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={e => handleChange("name", e.target.value)}
                    placeholder="Nombre de academia"
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={4}>
              <Form.Item
                label="Teléfono"
                validateStatus={phoneError ? "error" : ""}
                help={phoneError || ""}
              >
                {getFieldDecorator("phone", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: "Introduce un telefono!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={e => handleChange("phone", e.target.value)}
                    placeholder="Teléfono de academia"
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={8}>
              <Form.Item
                label="Email de contacto"
                validateStatus={emailError ? "error" : ""}
                help={emailError || ""}
              >
                {getFieldDecorator("email", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: "Introduce un email!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={e => handleChange("email", e.target.value)}
                    placeholder="Email"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={24} lg={10}>
              <Form.Item
                label="Calle"
                validateStatus={streetError ? "error" : ""}
                help={streetError || ""}
              >
                {getFieldDecorator("street", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: "Introduce una calle válida!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={e => handleChange("street", e.target.value)}
                    placeholder="Calle"
                  />
                )}
              </Form.Item>
            </Col>

            <Col md={24} lg={3}>
              <Form.Item
                label="Código Postal"
                validateStatus={postalCodeError ? "error" : ""}
                help={postalCodeError || ""}
              >
                {getFieldDecorator("_postal_code", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: "Introduce un código postal válido!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={e => handleChange("_postal_code", e.target.value)}
                    placeholder="Cod. Postal"
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={5}>
              <Form.Item
                label="Provincia"
                validateStatus={cityError ? "error" : ""}
                help={cityError || ""}
              >
                {getFieldDecorator("city", {
                  initialValue: undefined,
                  rules: [
                    {
                      required: true,
                      message: "Introduce una provincia válida!"
                    }
                  ]
                })(
                  <Select
                    style={{ width: "100%" }}
                    onChange={e => changeCity(e)}
                    placeholder="Selecciona una provincia"
                  >
                    {provinces.map(province => (
                      <Option value={province.id}>{province.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={6}>
              <Form.Item
                label="Localidad"
                validateStatus={localityError ? "error" : ""}
                help={localityError || ""}
              >
                {getFieldDecorator("locality", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: "Introduce una localidad válida!"
                    }
                  ]
                })(
                  <Select
                    style={{ width: "100%" }}
                    onChange={e => handleChange("locality", e)}
                    placeholder="Selecciona una localidad"
                  >
                    {towns.map(town => (
                      <Option value={town.id}>{town.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end" type="flex">
            <Col>
              <Form.Item>
                <Button
                  icon="folder-add"
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors(getFieldsError())}
                >
                  Guardar Nueva Academia
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export const Companies = Form.create({ name: "new_company_form" })(
  CompaniesView
);
