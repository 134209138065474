import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Table as TableAnt, Row, Col } from "antd";

const Wrapper = styled.div.attrs(props => ({
  className: "wrapper-table"
}))`
  margin: 30px 0;
  background: white;
  padding: 10px;
`;

const StyledTable = styled.div`
  && {
    background-color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
    margin-top: 15px;
    border-radius: 10px;
    thead[class*="ant-table-thead"] th {
      /* background-color: yellow !important; */
    }
  }
`;

const WrapperButtons = styled(Row)`
  border-bottom: 1px solid rgba(35, 37, 51, 0.1);
  @media (max-width: 48rem) {
    margin-bottom: 0;
    border-bottom: 0;
  }
  &.container-inner {
    padding: 40px 0;
  }
`;

const TableIcon = styled.i`
  span {
    color: ${props => props.theme.colors.primary};
    display: inline-block;
    position: relative;
    top: -3px;
    margin-right: 10px;
    @media (max-width: 48rem) {
      top: -1px;
    }
  }
`;

const Title = styled.h1`
  color: ${props => props.theme.colors.primary};
  font-weight: 100;
  display: inline-block;
  font-size: 24px;
  line-height: 29px;
  padding-bottom: 0;
  @media (max-width: 48rem) {
    font-size: 16px;
  }
`;

const SubTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  font-size: 0.9rem;
  display: block;
  &:after {
    content: "";
    background: ${props => props.theme.colors.primary};
    width: 30px;
    height: 2px;
    display: block;
    margin-top: 4px;
    @media (max-width: 48rem) {
      display: none;
    }
  }
  justify-content: flex-start;
  font-weight: 100;
  margin: 0 0 15px 0;
  font-size: 15px;
  padding: 0px;

  border-radius: 5px;
  @media (max-width: 48rem) {
    font-size: 1rem;
    border: 0;
    font-weight: 100;
    padding: 5px 10px;
    display: flex;
    justify-content: end;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 48rem) {
    justify-content: center;
    flex-direction: column;
    button {
      margin: 5px 0 !important;
    }
  }
`;

const Header = styled.div`
  padding: 10px;
  background: white;
  margin: 10px 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
`;

export const Table = ({
  columns,
  data,
  tableTitle,
  tableSubTitle,
  buttonsHeader,
  header,
  iconTitle,
  noScroll,
  pagination,
  onChange,
  rowKey
}) => {
  const parsedColumns = columns.map(column => ({ ...column, ellipsis: true }));

  return (
    <Wrapper>
      <WrapperButtons>
        <Row justify="center" style={{ width: "100%" }}>
          <Col xs={24} md={12}>
            {iconTitle && <TableIcon>{iconTitle}</TableIcon>}
            <Title>{tableTitle}</Title>
            {tableSubTitle && <SubTitle>{tableSubTitle}</SubTitle>}
          </Col>
          <Col xs={24} md={12}>
            <Buttons>{buttonsHeader}</Buttons>
          </Col>
        </Row>
      </WrapperButtons>
      {!data || data.length === 0 ? (
        <div>No hay datos</div>
      ) : (
        <>
          {header && <Header>{header}</Header>}
          <StyledTable>
            <TableAnt
              rowKey={rowKey}
              tableLayout="auto"
              scroll={{
                x: !noScroll ? "max-content" : 0
              }}
              columns={parsedColumns}
              dataSource={data}
              pagination={pagination || false}
              onChange={pagination => onChange(pagination)}
            />
          </StyledTable>
        </>
      )}
    </Wrapper>
  );
};

Table.propTypes = {
  /** Include info about every column */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      dataIndex: PropTypes.string
    })
  ).isRequired,
  /** Data content of the table */
  data: PropTypes.array.isRequired,
  /** Table title */
  tableTitle: PropTypes.string,
  /** Table subtitle */
  tableSubTitle: PropTypes.string,
  /** Buttons to inject in the header of table */
  buttonsHeader: PropTypes.array,
  /** Options to show message and button when data is empty */
  emptyOpts: PropTypes.shape({
    message: PropTypes.string,
    button: PropTypes.shape({
      label: PropTypes.string,
      fn: PropTypes.func
    })
  }),
  header: PropTypes.node
};

Table.defaultProps = {
  header: null,
  tableTitle: "",
  tableSubTitle: "",
  buttonsHeader: [],
  emptyOpts: {
    message: "No hay datos añadidos",
    button: {
      label: "Añadir nuevo",
      fn: () => console.log("Add a handler")
    }
  }
};

export default Table;
