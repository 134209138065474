import React, { useEffect } from "react";
import {
  Switch,
  Typography,
  Row,
  Col,
  Form,
  Icon,
  Input,
  Button,
  Divider
} from "antd";
import ContainerTitle from "components/ContainerTitle";
import Loading from "components/Loading";

const { Text } = Typography;

const UserView = ({ getUser, setUserData, data, ...props }) => {
  const { id } = props.match.params;

  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
    setFieldsValue
  } = props.form;

  useEffect(() => {
    getUser(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setUserData(props.form.getFieldsValue(), id);
  };
  const handleChange = (key, value) => {
    setFieldsValue({ [key]: value });
  };

  const nameError = isFieldTouched("name") && getFieldError("name");
  const lastNameError = isFieldTouched("lastName") && getFieldError("lastName");
  const usernameError = isFieldTouched("username") && getFieldError("username");
  const emailError = isFieldTouched("email") && getFieldError("email");

  return !!data && Object.keys(data).length ? (
    <>
      <ContainerTitle
        title={
          <>
            <Text code>{data.id}</Text>
            {data.name}
          </>
        }
        buttons={[
          <Button
            type="dashed"
            onClick={() => props.history.goBack()}
            icon="left"
          >
            Volver
          </Button>
        ]}
      >
        <Form onSubmit={handleSubmit}>
          <Row gutter={16}>
            <Col md={24} lg={8}>
              <Form.Item
                label="Nombre"
                validateStatus={nameError ? "error" : ""}
                help={nameError || ""}
              >
                {getFieldDecorator("name", {
                  initialValue: data.name,
                  rules: [
                    {
                      required: true,
                      message: "Introduce un nombre!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={e => handleChange("name", e.target.value)}
                    placeholder="Nombre"
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={10}>
              <Form.Item
                label="Apellidos"
                validateStatus={lastNameError ? "error" : ""}
                help={lastNameError || ""}
              >
                {getFieldDecorator("last_name", {
                  initialValue: data.last_name,
                  rules: [
                    {
                      required: true,
                      message: "Introduce unos apellidos!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    onChange={e => handleChange("last_name", e.target.value)}
                    placeholder="Apellidos"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} lg={6}>
              <Form.Item
                label="Email"
                validateStatus={emailError ? "error" : ""}
                help={emailError || ""}
              >
                {getFieldDecorator("email", {
                  initialValue: data.email,
                  rules: [
                    {
                      required: true,
                      message: "Introduce un email!"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    disabled
                    onChange={e => handleChange("email", e.target.value)}
                    placeholder="Email"
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={6}>
              <Form.Item
                style={{ display: "none" }}
                label="Nombre de usuario"
                validateStatus={emailError ? "error" : ""}
                help={emailError || ""}
              >
                {getFieldDecorator("username", {
                  initialValue: data.username,
                  rules: [
                    {
                      required: true,
                      message: "Introduce un Nombre de usuario"
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    disabled
                    onChange={e => handleChange("username", e.target.value)}
                    placeholder="Nombre de usuario"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} lg={3}>
              <Form.Item label="Usuario eliminado">
                {getFieldDecorator("is_anonymized", {
                  valuePropName: "checked",
                  initialValue: data.is_anonymized
                })(
                  <Switch
                    disabled
                    checkedChildren="Eliminado"
                    unCheckedChildren="Activo"
                  />
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={3}>
              <Form.Item label="Usuario Activo">
                {getFieldDecorator("active", {
                  valuePropName: "checked",
                  initialValue: data.active
                })(
                  <Switch
                    checkedChildren="Activo"
                    unCheckedChildren="Deshabilitado"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" type="flex">
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors(getFieldsError())}
                >
                  Guardar los datos
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider />
      </ContainerTitle>
    </>
  ) : (
    <Loading />
  );
};

export const User = Form.create({ name: "user_form" })(UserView);
