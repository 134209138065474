import { actions as actionAuth } from "../core/Auth";

import { showNotification } from "utils/utilities";

function getMessageError(errorCode, message) {
  if (message) {
    return message === "" || message === "Forbidden"
      ? "No tiene permisos"
      : message;
  }
  switch (errorCode) {
    case 400:
      return "No hemos encontrado el recurso";
    case 401:
      return "Inicia sesión de nuevo";
    case 403:
      return "No tiene permisos para realizar la acción";
    default:
      return "Error con el servidor";
  }
}

function catchError(err, dispatch, title, type_error) {
  let parser = JSON.parse(err.message);
  // if (parser.error) {
  //   parser = parser.error;
  // }
  const label =
    parser.code === 401 || parser.code === 403
      ? "Datos de acceso incorrectos"
      : title;

  showNotification(label, getMessageError(parser.code, parser.data), "warning");

  if ((parser.error && parser.error.code === 403) || parser.code === 403) {
    return dispatch({
      type: actionAuth.AUTH_ERROR
    });
  } else {
    return dispatch({
      type: type_error,
      payload: parser.data
    });
  }
}

export { getMessageError, catchError };
