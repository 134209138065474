import { connect } from "react-redux";

import { User as View } from "views/User";
import { getUser, setUserData } from "core/User/actions";

const mapStateToProps = state => ({
  data: state.user.data
});

const mapDispatchToProps = dispatch => ({
  getUser: id => dispatch(getUser(id)),
  setUserData: (data, id) => dispatch(setUserData(data, id))
});

export const User = connect(
  mapStateToProps,
  mapDispatchToProps
)(View);
