//import { Home } from "containers/private/Home";
import { Companies } from "containers/private/Companies";
import { Company } from "containers/private/Company";
import { Advertisers } from "containers/private/Advertisers";
import { Billing } from "views/Billing";
import { Statistic } from "views/Statistic";

import Advertiser from "views/Advertiser";
import Analytics from "views/Analytics";
import { User } from "containers/private/User";
import { UsersList } from "containers/private/UsersList";
import { ActionsUsers } from "containers/private/ActionsUsers";

export const mainRoute = "/dashboard";

var privateRoutes = [
  {
    path: `${mainRoute}/estadisticas`,
    component: Statistic,
    icon: "dot-chart",
    name: "Estadísticas"
  },
  {
    path: `${mainRoute}/academias`,
    name: "Academias",
    icon: "project",
    component: Companies
  },
  {
    path: `${mainRoute}/acciones`,
    name: "Acciones de Usuarios",
    icon: "idcard",
    component: ActionsUsers
  },
  {
    path: `${mainRoute}/usuarios`,
    name: "Accesos de Usuarios",
    icon: "idcard",
    component: UsersList
  },
  {
    path: `${mainRoute}/anunciantes`,
    name: "Anunciantes",
    icon: "team",
    component: Advertisers
  },
  {
    path: `${mainRoute}/academias/:id`,
    component: Company,
    hideMenu: true
  },
  {
    path: `${mainRoute}/academias/:id/facturacion`,
    component: Billing,
    hideMenu: true
  },

  {
    path: `${mainRoute}/anunciante/:id`,
    name: "Anunciante",
    hideMenu: true,
    component: Advertiser
  },
  {
    path: `${mainRoute}/analiticas`,
    name: "Analitica Marketplace",
    icon: "area-chart",
    component: Analytics
  },
  {
    path: `${mainRoute}/academias/user/:id`,
    component: User,
    hideMenu: true
  }
];
export default privateRoutes;
