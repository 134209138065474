import React, { useEffect } from "react";
import Drawer from "components/Drawer";
import { Switch, Row, Col, Form, Icon, Input, Button } from "antd";

const AddAdminView = ({ onAddAdmin, open, closeDrawer, ...props }) => {
  useEffect(() => {
    validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
    setFieldsValue,
    validateFields,
    resetFields
  } = props.form;

  const hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  const handleSubmit = e => {
    e.preventDefault();
    onAddAdmin(props.form.getFieldsValue());
    resetFields();
    closeDrawer();
  };
  const handleChange = (key, value) => {
    setFieldsValue({ [key]: value });
  };

  const nameError = isFieldTouched("name") && getFieldError("name");
  const lastNameError = isFieldTouched("lastName") && getFieldError("lastName");
  const emailError = isFieldTouched("email") && getFieldError("email");

  return (
    <Drawer
      title="Crear nuevo usuario administrador"
      placement="top"
      closable={true}
      onClose={() => {
        resetFields();
        closeDrawer();
      }}
      visible={open}
    >
      <Form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col md={24} lg={8}>
            <Form.Item
              label="Nombre"
              validateStatus={nameError ? "error" : ""}
              help={nameError || ""}
            >
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Introduce un nombre!"
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  onChange={e => handleChange("name", e.target.value)}
                  placeholder="Nombre"
                />
              )}
            </Form.Item>
          </Col>
          <Col md={24} lg={10}>
            <Form.Item
              label="Apellidos"
              validateStatus={lastNameError ? "error" : ""}
              help={lastNameError || ""}
            >
              {getFieldDecorator("last_name", {
                rules: [
                  {
                    required: true,
                    message: "Introduce unos apellidos!"
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  onChange={e => handleChange("last_name", e.target.value)}
                  placeholder="Apellidos"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={24} lg={6}>
            <Form.Item
              label="Email de contacto"
              validateStatus={emailError ? "error" : ""}
              help={emailError || ""}
            >
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Introduce un email!"
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  onChange={e => handleChange("email", e.target.value)}
                  placeholder="Email"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={24} lg={3}>
            <Form.Item label="Usuario eliminado">
              {getFieldDecorator("is_anonymized", {
                valuePropName: "checked"
              })(
                <Switch
                  disabled
                  checkedChildren="Eliminado"
                  unCheckedChildren="Activo"
                />
              )}
            </Form.Item>
          </Col>
          <Col md={24} lg={3}>
            <Form.Item label="Usuario Activo">
              {getFieldDecorator("active", {
                valuePropName: "checked"
              })(
                <Switch
                  checkedChildren="Activo"
                  unCheckedChildren="Deshabilitado"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" type="flex">
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={hasErrors(getFieldsError())}
              >
                Guardar los datos
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
export const AddAdmin = Form.create({ name: "add_company_form" })(AddAdminView);
