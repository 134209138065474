import apiClient, { clearSession } from "./apiClient";
import PrivateRoute from "./privateRoute";
import * as formsHelpers from "./formsHelpers";
import * as utilities from "./utilities";
import parseJwt from "./jwtDecode";
import privateRoutes from "routes/private";
// eslint-disable-next-line
Array.prototype.groupBy = function(prop, key = "grouped-") {
  return this.reduce(function(groups, item) {
    const splitted = prop.split(".");
    let val = "";

    if (splitted.length === 1) {
      val = item[prop];
    } else {
      splitted.map(inner => (val = val ? val[inner] : item[inner]));
    }

    const keyValue = val;
    groups[`${key}${keyValue}`] = groups[`${key}${keyValue}`] || [];
    groups[`${key}${keyValue}`].push(item);
    return groups;
  }, {});
};

const renameKeys = (keysMap, obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const renamedObject = {
      [keysMap[key] || key]: obj[key]
    };
    return {
      ...acc,
      ...renamedObject
    };
  }, {});
};

const allowedUrl = (path, isLogin, isAdmin) => {
  const selected = privateRoutes.filter(route => {
    return route.path === path;
  })[0];

  return isLogin && isAdmin === selected.isAdmin;
};

export {
  allowedUrl,
  renameKeys,
  PrivateRoute,
  formsHelpers,
  clearSession,
  utilities,
  parseJwt
};
export default apiClient;
