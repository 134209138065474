import React, { Fragment } from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import pagesRoutes from "routes/public";

const NotFound = () => <div>not found</div>;
class Public extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "unset";
  }
  render() {
    return (
      <Fragment>
        <Switch>
          {pagesRoutes.map((prop, key) => {
            if (prop.redirect) {
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            }
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          })}

          <Route component={NotFound} />
        </Switch>
      </Fragment>
    );
  }
}

Public.propTypes = {};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(Public);
