import { Spin } from "antd";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 30px 50px;
  margin: 20px 0;
  height: calc(100vh - 200px);
`;

const Loading = () => (
  <Wrapper>
    <Spin size="large" />
  </Wrapper>
);

export default Loading;
