import React, { useEffect, useState } from "react";
import NewTable from "components/NewTable";
import {
  Select,
  Typography,
  Row,
  Col,
  Form,
  Icon,
  Divider,
  Tag,
  Button
} from "antd";

import ContainerTitle from "components/ContainerTitle";
import Loading from "components/Loading";
import apiClient from "utils";
import { compare } from "utils/helpers";
import styled from "styled-components";
import { Link } from "react-router-dom";

const { Text } = Typography;
const { Option } = Select;

const StyledTag = styled(Tag)`
  && {
    font-size: 16px;
    padding: 5px 10px;
    width: 100%;
  }
`;

const BillingView = props => {
  const [data, setData] = useState();
  const [audio, setAudio] = useState(0);
  const [video, setVideo] = useState(0);
  const [report, setReport] = useState();

  const [provinces, setProvinces] = useState([]);
  const [towns, setTowns] = useState([]);
  const { id } = props.match.params;

  const { getFieldDecorator } = props.form;

  useEffect(() => {
    apiClient({
      path: `super/billing/${id}`,
      method: "GET"
    }).then(response => {
      apiClient({
        path: `advertiser-data/combos`,
        method: "GET"
      }).then(async json => {
        await setProvinces(
          json.provinces.sort((a, b) => compare(a, b, "name"))
        );
        if (data.city) {
          apiClient({
            path: `advertiser-data/town/${parseInt(data.city)}`,
            method: "GET"
          }).then(async json => {
            await setTowns(json.town.sort((a, b) => compare(a, b, "name")));
          });
        }
      });
      const { data, moduleAudio, moduleVideo, report } = response;
      moduleAudio ? setAudio(moduleAudio.status) : setAudio(0);
      moduleVideo ? setVideo(moduleVideo.status) : setVideo(0);

      setData(data);
      setReport(report);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Mes",
      dataIndex: "month",
      key: "month",
      width: 100,
      fixed: "left",
      render: month => {
        switch (month) {
          case 1:
            return "Enero";
          case 2:
            return "Febrero";
          case 3:
            return "Marzo";
          case 4:
            return "Abril";
          case 5:
            return "Mayo";
          case 6:
            return "Junio";
          case 7:
            return "Julio";
          case 8:
            return "Agosto";
          case 9:
            return "Septiembre";
          case 10:
            return "Octubre";
          case 11:
            return "Noviembre";
          case 12:
            return "Diciembre";
        }
      }
    },
    {
      title: "Usuarios activos",
      dataIndex: "active_users",
      key: "active_users",
      width: 100
    },
    {
      title: "Audios",
      dataIndex: "audio",
      key: "audio",
      render: audio => {
        switch (audio) {
          case 0:
            return "Inhabilitado";
          case 1:
            return "Habilitado";
          case 2:
            return "Suspendido";
        }
      }
    },
    {
      title: "Videos",
      dataIndex: "video",
      key: "video",
      render: video => {
        switch (video) {
          case 0:
            return "Inhabilitado";
          case 1:
            return "Habilitado";
          case 2:
            return "Suspendido";
        }
      }
    },
    {
      title: "Almacenaje contratado",
      dataIndex: "storage",
      key: "storage",
      render: text => `${Math.round(text / 1000000)} MB`
    },
    {
      title: "Almacenaje ocupado",
      dataIndex: "busy_storage",
      key: "busy_storage",
      render: text => `${Math.round(text / 1000000)} MB`
    }
  ];

  const parserStorage = value => {
    switch (value) {
      case 500000000:
        return <StyledTag>500 MB</StyledTag>;
      case 2000000000:
        return <StyledTag>2 GB</StyledTag>;
      case 5000000000:
        return <StyledTag style={{ fontSize: "16px" }}>5 GB</StyledTag>;
      case 10000000000:
        return <StyledTag style={{ fontSize: "16px" }}>10 GB</StyledTag>;
      case 15000000000:
        return <StyledTag style={{ fontSize: "16px" }}>15 GB</StyledTag>;
    }
  };

  return !!data ? (
    <>
      <ContainerTitle
        title={
          <>
            <Text code>{data.id}</Text>
            {data.name}
          </>
        }
        buttons={[
          <Link to={`/dashboard/academias/${id}`}>
            <Button icon="arrow-left">Ir a detalle </Button>
          </Link>
        ]}
      >
        <Form>
          <Row
            type="flex"
            justify="center"
            align="middle"
            gutter={16}
            style={{ textAlign: "center" }}
          >
            <Col xs={24} md={8}>
              <Icon type="audio" theme="filled" />
              <h3>Módulo de audio</h3>

              <Form.Item>
                <Select
                  style={{ width: 175 }}
                  placeholder="Modulo Audio"
                  disabled
                  value={audio}
                >
                  <Option value={0}>Deshabilitado</Option>
                  <Option value={1}>Habilitado</Option>
                  <Option value={2}>Suspendido</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Icon type="video-camera" theme="filled" />
              <h3>Módulo de video</h3>

              <Form.Item>
                <Select
                  style={{ width: 175 }}
                  placeholder="Modulo Video"
                  disabled
                  value={video}
                >
                  <Option value={0}>Deshabilitado</Option>
                  <Option value={1}>Habilitado</Option>
                  <Option value={2}>Suspendido</Option>
                </Select>
              </Form.Item>
            </Col>

            <Divider style={{ height: "100px" }} type="vertical" />
            <Col xs={24} md={7}>
              <Form.Item label="Almacenamiento contratado">
                {parserStorage(data.storage)}
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col md={24} lg={4}>
              <Form.Item label="NIF">
                <StyledTag>{data._n_i_f}</StyledTag>
              </Form.Item>
            </Col>
            <Col md={24} lg={8}>
              <Form.Item label="Nombre de Academia">
                <StyledTag>{data.name}</StyledTag>
              </Form.Item>
            </Col>
            <Col md={24} lg={4}>
              <Form.Item label="Teléfono">
                <StyledTag>{data.phone}</StyledTag>
              </Form.Item>
            </Col>
            <Col md={24} lg={8}>
              <Form.Item label="Email de contacto">
                <StyledTag>{data.email}</StyledTag>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} lg={10}>
              <Form.Item label="Calle">
                <StyledTag>{data._street}</StyledTag>
              </Form.Item>
            </Col>

            <Col md={24} lg={3}>
              <Form.Item label="Código Postal">
                <StyledTag>{data._postal_code}</StyledTag>
              </Form.Item>
            </Col>
            <Col md={24} lg={6}>
              <Form.Item label="Provincia">
                {getFieldDecorator("city", {
                  initialValue:
                    (data.city !== "" && parseInt(data.city)) || undefined
                })(
                  <Select disabled style={{ width: "100%" }}>
                    {provinces.map(province => (
                      <Option value={province.id}>{province.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col md={24} lg={5}>
              <Form.Item label="Localidad">
                {getFieldDecorator("locality", {
                  initialValue:
                    (data.locality !== "" && parseInt(data.locality)) ||
                    undefined
                })(
                  <Select disabled style={{ width: "100%" }}>
                    {towns.map(town => (
                      <Option value={town.id}>{town.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider />
      </ContainerTitle>
      {report && (
        <div style={{ maxWidth: "1800px", margin: "0 auto" }}>
          <NewTable
            tableTitle="Desglose por meses"
            tableSubTitle=""
            buttonsHeader={[]}
            header={""}
            iconTitle={null}
            columns={columns}
            data={report}
          />
        </div>
      )}
    </>
  ) : (
    <Loading />
  );
};

export const Billing = Form.create({ name: "billing_form" })(BillingView);
