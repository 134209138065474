// imports
import apiClient from "utils";

import { showNotification } from "utils/utilities";

// Constants
export const USER_GET = "@@USER/USER_GET";
export const USER_GET_LIST = "@@USER/USER_GET_LIST";

export const getUser = id => dispatch => {
  return apiClient({
    path: `super/admin/${id}`,
    method: "GET"
  })
    .then(json => {
      return dispatch({
        type: USER_GET,
        payload: json.data
      });
    })
    .catch(err => showNotification("Error", err.message, "error"));
};

export const getUsers = page => dispatch => {
  return apiClient({
    path: `super/users?page=${page}`,
    method: "GET"
  })
    .then(json => {
      return dispatch({
        type: USER_GET_LIST,
        payload: json.data
      });
    })
    .catch(err => showNotification("Error", err.message, "error"));
};

export const getActionsUsers = page => dispatch => {
  return apiClient({
    path: `super/actionsUsers?page=${page}`,
    method: "GET"
  })
    .then(json => {
      return dispatch({
        type: USER_GET_LIST,
        payload: json.data
      });
    })
    .catch(err => showNotification("Error", err.message, "error"));
};

export const setUserData = (data, id) => dispatch => {
  return apiClient({
    path: `super/admin/${id}`,
    method: "PUT",
    body: data
  })
    .then(json => {
      showNotification(
        "Guardar usuario",
        "Se ha guardado el usuario",
        "success"
      );
    })
    .catch(err => showNotification("Error", err.message, "error"));
};
