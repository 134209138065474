import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

const WrapperLogo = styled.div`
  width: 120px;
  height: 20px;
  margin: 7px 28px 16px -6px;
  float: left;
`;

const Label = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
  }
  span {
    font-family: "Inter";
    font-size: 30px;
    line-height: 44px;
    text-align: center;
    color: white;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
`;
const Logo = props => (
  <WrapperLogo {...props}>
    <Label to="/" onClick={() => window.scroll(0, 0)}>
      <span>opoque</span>
    </Label>
  </WrapperLogo>
);

export default Logo;
