import * as actions from "./actions";

export const initialState = {
  listReal: [],
  listDemo: [],
  company: {}
};

const reducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case actions.COMPANIES_GET:
      return {
        listReal: action.payload.filter(
          company => company.type_user === "admin"
        ),
        listDemo: action.payload.filter(company => company.type_user === "demo")
      };
    case actions.COMPANY_GET:
      return {
        company: action.payload
      };
    case actions.COMPANY_ADD:
      return {
        ...state,
        list: [...state.list, action.payload]
      };
    case actions.ADMIN_ADD:
      return {
        ...state,
        company: {
          ...state.company,
          users: [...state.company.users, action.payload.data]
        }
      };
    default:
      return state;
  }
};

export { actions };
export default reducer;
