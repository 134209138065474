import theme from "./defaultTheme";
import { mergeDeep } from "../utils/utilities";

const corporativeTheme = {
  name: "CORPORATIVE",
  font: {
    fontSize: "14px",
    textDecoration: "none"
  },
  colors: {
    primary: "#FF6000"
  }
};

const cloned = Object.assign({}, theme);
const corporative = mergeDeep(cloned, corporativeTheme);

export default corporative;
