import styled from "styled-components";
import { Button, Divider } from "antd";
import React from "react";

const StyledLabel = styled("h3")`
  display: inline-block;
  width: auto;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  font-size: 18px;
  margin: 10px 0;
`;

const WrapperContainerTitle = styled("div")`
  padding: 1rem 1rem 0.5rem 1rem;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
  background-color: ${props => props.theme.colors.light};
  box-shadow: "0px 0px 12px -3px #ccc";
  max-width: 1800px;
`;

export const StyledButton = styled(Button)`
  width: auto;
  margin-top: 4px;
  float: right;
`;

const WrapperButtons = styled.div`
  display: flex;
  margin-top: 7px;
  button {
    margin-left: 5px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContainerTitle = ({ title, buttons, children }) => {
  return (
    <WrapperContainerTitle>
      <Header>
        <StyledLabel>{title}</StyledLabel>
        <WrapperButtons>{buttons.map(button => button)}</WrapperButtons>
      </Header>

      <Divider type="horizontal" />
      {children}
    </WrapperContainerTitle>
  );
};

export default ContainerTitle;
