import { Login } from "containers/public/Login";

const publicRoutes = [
  {
    path: "/login",
    component: Login
  },
  { redirect: true, path: "/", pathTo: "/login", name: "Dashboard" }
];

export default publicRoutes;
