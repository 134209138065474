// imports
import apiClient from "utils";

import { showNotification } from "utils/utilities";

// Constants
export const COMPANIES_GET = "@@COMPANIES/COMPANIES_GET";
export const COMPANY_GET = "@@COMPANIES/COMPANY_GET";
export const COMPANY_ADD = "@@COMPANIES/COMPANY_ADD";
export const ADMIN_ADD = "@@COMPANIES/ADMIN_ADD";

export const getCompanies = () => dispatch => {
  return apiClient({
    path: `super/companies`,
    method: "GET"
  })
    .then(json => {
      return dispatch({
        type: COMPANIES_GET,
        payload: json.data
      });
    })
    .catch(err => showNotification("Error", err.message, "error"));
};

export const getCompany = id => dispatch => {
  return apiClient({
    path: `super/company/${id}`,
    method: "GET"
  })
    .then(json => {
      return dispatch({
        type: COMPANY_GET,
        payload: {
          ...json.promotion,
          ...json.data,
          moduleAudio: json.moduleAudio,
          moduleVideo: json.moduleVideo,
          subdomain: json.subdomain
        }
      });
    })
    .catch(err => showNotification("Error", err.message, "error"));
};

export const setCompanyData = (data, id) => dispatch => {
  const payload = {
    audio: data.audio,
    video: data.video,
    email: data.email,
    storage: data.storage,
    name: data.name,
    nif: data.nif,
    nif_responsible: data.nif_responsible,
    address: data.street,
    streetNumber: data.number_st,
    postalCode: data.postalCode,
    locality: data.locality,
    city: data.city,
    phone: data.phone,
    promotional_discount_time: data.promotional_discount_time,
    promotional_discount_percent: data.promotional_discount_percent,
    responsible: data.responsible
  };

  return apiClient({
    path: `super/company_set/${id}`,
    method: "PUT",
    body: payload
  })
    .then(json => {
      showNotification(
        "Guardar academia",
        "Se ha guardado la academia",
        "success"
      );
    })
    .catch(err => showNotification("Error", err.message, "error"));
};

export const createCompany = data => dispatch => {
  return apiClient({
    path: `super/company/register`,
    method: "POST",
    body: data
  })
    .then(json => {
      dispatch({ type: COMPANY_ADD, payload: json.data });
      showNotification("Enhorabuena!", "Se ha guardado la academia", "success");
    })
    .catch(err => {
      showNotification("Error", err.message, "error");
    });
};

export const addNewAdmin = (data, id) => dispatch => {
  const parsedData = { ...data, company_id: id };
  return apiClient({
    path: `super/user/register`,
    method: "POST",
    body: parsedData
  })
    .then(json => {
      dispatch({
        type: ADMIN_ADD,
        payload: { data: json.data, id_company: id }
      });
      showNotification(
        "Enhorabuena!",
        "Se ha guardado el usuario administrador",
        "success"
      );
    })
    .catch(err => {
      showNotification("Error", err.message, "error");
    });
};
