import theme from "./defaultTheme";
import { mergeDeep } from "../utils/utilities";
import { mergeObjectIE } from "utils/helpers";

const alternativeTheme = {
  name: "ALTERNATIVE",
  font: {
    fontSize: "14px",
    textDecoration: "none"
  }
};
const cloned = mergeObjectIE({}, theme);

const alternative = mergeDeep(cloned, alternativeTheme);
export default alternative;
