import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { Spin, Row, Col, Button, Icon, Alert, Switch } from "antd";

import noTeacher from "../assets/noTeacher.svg";
import { showNotification } from "utils/utilities";
import apiClient from "utils";

const ContentTeacher = styled.div`
  @media (max-width: 992px) {
    padding-top: 60px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ImageAcademy = styled.img.attrs(props => ({
  role: "img",
  "aria-label": "Logo",
  src: props.logo
}))`
  max-width: 360px;
  height: 120px;
  margin: 10px;
  background-size: contain;
`;

const Type = styled.div`
  width: 120px;
  border: 2px solid
    ${props => (props.type === "Academia" ? "#FC7F65" : "#36A060")};
  color: ${props => (props.type === "Academia" ? "#FC7F65" : "#36A060")};
  padding: 3px 10px;
  text-align: center;
  font-weight: bold;
`;

const Name = styled.h1`
  color: #454545;
  margin: 0;
  padding-top: 10px;
`;
const Location = styled.span`
  font-size: 14px;
  i {
    padding-right: 10px;
  }
`;

const Address = styled.p`
  font-size: 13px;
`;

const Competition = styled.div`
  margin-top: 20px;

  h2 {
    color: #454545;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }
  span {
    padding: 10px;
    margin: 5px 10px;
    display: inline-block;
    border: 2px solid #e4e4e4;
    border-width: 2px 0;
    @media (max-width: 992px) {
      display: block;
      text-align: center;
    }
  }
`;

const Prices = styled.div`
  display: flex;
  align-items: center;
  i {
    margin: 0 10px;
  }
`;

const Divider = styled.div`
  width: 90%;
  height: 1px;
  background: #d9d0d0;
  margin: 10px 0;
`;

const RestContent = styled.div`
  width: 70%;
  margin: 10px 0;
  @media (max-width: 992px) {
    width: 90%;
  }
`;

const Price = styled.div`
  h2 {
    color: #454545;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }

  span {
    text-align: center;
    display: flex;
    align-items: center;
    background: #3fb88e;
    color: white;
    padding: 5px 10px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: bold;
    font-family: "Inter";
    em {
      text-transform: uppercase;
      font-size: 12px;
      border-right: 1px solid white;
      padding-right: 5px;
      margin-right: 5px;
      line-height: 30px;
      display: inline-block;
      font-weight: 100;
      font-style: normal;
    }
  }
`;

const Title = styled.div`
  margin-top: 10px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  text-align: ${props => (props.right ? "right" : "left")};
  @media (max-width: 992px) {
    text-align: left;
  }
`;

const Values = styled.p`
  font-weight: 100;
  font-size: 16px;
  margin-bottom: 0;
  text-align: ${props => (props.right ? "right" : "left")};
  @media (max-width: 992px) {
    text-align: left;
  }
`;

const Description = styled.p`
  background: white;
  padding: 15px;
  color: #454545;
  margin: 20px 0;
  width: 90%;
`;

const WrapperSpin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
`;

const Validation = styled.div`
  margin: 20px auto;
  display: block;
  text-align: center;
`;

const Advertiser = ({ history, ...props }) => {
  const {
    match: {
      params: { id }
    }
  } = props;

  const [data, setData] = useState({
    values: null,
    formations: null,
    available: false,
    picture: null
  });

  const getUrlImage = filename => {
    if (process.env.REACT_APP_ENABLED_S3 === "true") {
      return `${filename}`;
    } else {
      return `${process.env.REACT_APP_URL_PUBLIC}picture_advertisers/${filename}`;
    }
  };

  useEffect(() => {
    let url = process.env.REACT_APP_API_URL;

    axios
      .get(`${url}advertiser-data/advertiser`, { params: { id } })
      .then(function(response) {
        setData({
          values: response.data.advertisers,
          picture: response.data.picture,
          formations: response.data.formations
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateAdvertiser = () => {
    apiClient({
      path: "super/validate-advertiser",
      method: "POST",
      body: { id, available: data.available }
    })
      .then(function(response) {
        history.push("/dashboard/anunciantes");
        showNotification(
          "Validación",
          "Se ha validado correctamente el anuncio",
          "success"
        );
      })
      .catch(() => {
        showNotification(
          "Validación",
          "No se ha podido validar el anuncio",
          "error"
        );
      });
  };

  return data.values ? (
    <ContentTeacher>
      <Validation>
        <Button
          style={{ margin: "10px auto" }}
          type="dashed"
          icon="rollback"
          onClick={() => {
            history.length < 4
              ? history.push("/dashboard/anunciantes")
              : history.goBack();
          }}
        >
          Volver al listado
        </Button>
        {!data.values.verified && (
          <>
            <Alert
              message="Validar anunciante"
              description="Vista previa del anuncio. Pulse en el siguiente botón para verificar el anuncio."
              type="info"
              showIcon
            />
            <Switch
              onChange={checked => setData({ ...data, available: checked })}
              style={{ display: "block", margin: "10px auto" }}
              checkedChildren="Disponible en Opoque"
              unCheckedChildren="No disponible en opoque"
            />

            <Button
              style={{ marginTop: "10px" }}
              type="danger"
              size="large"
              icon="warning"
              onClick={() => validateAdvertiser()}
            >
              Validar anuncio
            </Button>
          </>
        )}
      </Validation>
      <Wrapper>
        {data.picture ? (
          <ImageAcademy logo={getUrlImage(data.picture)} />
        ) : (
          <ImageAcademy logo={noTeacher} />
        )}
        <Type type={data.values.trainer.name}>{data.values.trainer.name}</Type>
        <Name>{data.values.name}</Name>
        {data.values.town &&
          data.values.town.name &&
          data.values.province &&
          data.values.province.name && (
            <Location>
              <Icon type="environment" />
              {data.values.town.name}, {data.values.province.name}
            </Location>
          )}
        {data.values.address && data.values.postal_code && (
          <Address>
            {data.values.address} - {data.values.postal_code}
          </Address>
        )}
        <Divider />
        <Competition>
          <h2>Preparación de</h2>
          {data.values.competition.map(competition => (
            <span>{competition.name}</span>
          ))}
        </Competition>
        {data.values.max_price && data.values.min_price && (
          <Price>
            <h2>Precio</h2>
            <Prices>
              <span>
                <em>mínimo</em>
                {data.values.min_price}€
              </span>
              <Icon type="swap" />
              <span>
                <em>máximo</em>
                {data.values.max_price}€
              </span>
            </Prices>
          </Price>
        )}
        <Button
          style={{ marginTop: "20px" }}
          size="large"
          type="primary"
          icon="notification"
        >
          Contactar con la academia
        </Button>
        <Divider />
        <RestContent>
          <Row type="flex" justify="space-between">
            <Col xs={24} lg={12}>
              <Title>Tipo de Preparación</Title>
              <Values>
                {data.values.preparation
                  .map(preparation => preparation.name)
                  .join(", ")}
              </Values>
            </Col>
            <Col xs={24} lg={12}>
              <Title right>Horario</Title>
              <Values right>
                {data.values.schedule === "1"
                  ? "Mañanas"
                  : data.values.schedule === "2"
                  ? "Tardes"
                  : data.values.schedule === "3"
                  ? "Indiferente"
                  : "No especificado"}
              </Values>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col xs={24} lg={12}>
              {data.values.degree.length > 0 && (
                <>
                  <Title>Formación</Title>
                  {data.formations.map((formation, index) => (
                    <Values>
                      {formation.degree.name}{" "}
                      {formation.speciality &&
                        formation.speciality.id &&
                        `en ${formation.speciality.name}`}
                    </Values>
                  ))}
                </>
              )}
            </Col>
            {data.values.experience && (
              <Col xs={24} lg={12}>
                <Title right>Experiencia</Title>
                <Values right>Desde {data.values.experience}</Values>
              </Col>
            )}
          </Row>
        </RestContent>
        {data.values.description && (
          <Description>{data.values.description}</Description>
        )}
      </Wrapper>
    </ContentTeacher>
  ) : (
    <WrapperSpin>
      <Spin size="large" tip="Cargando el resultado..." />
    </WrapperSpin>
  );
};

export default Advertiser;
