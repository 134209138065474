import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Typography,
  Divider,
  Select,
  Table,
  Tag,
  Tooltip
} from "antd";
import apiClient from "utils";
import { fancyTimeFormat } from "utils/helpers";
import { getExtension } from "utils/utilities";

const { Title } = Typography;
const { Option } = Select;

const Wrapper = styled.div`
  && {
    max-width: 95%;
    margin: 20px auto;
    padding: 20px;
    background: white;
    td,
    th {
      text-align: center;
    }
  }
`;

const StyledTag = styled(Tag)`
  && {
    background: #1890ff;
    color: white;
  }
`;

const ZeroTag = styled(Tag)`
  && {
    background: #ff4d4f;
    color: white;
  }
`;

const columns = [
  {
    title: "Dia",
    dataIndex: "day",
    key: "day",
    fixed: "left",
    width: 70
  },
  {
    title: (
      <Tooltip title="Número de nuevos usuarios profesor. ">
        <span>Nuevos Profesores</span>
      </Tooltip>
    ),
    dataIndex: "new_teachers",
    key: "new_teachers",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de nuevos usuarios alumno.">
        <span>Nuevos Alumnos</span>
      </Tooltip>
    ),
    dataIndex: "new_pupils",
    key: "new_pupils",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de usuarios activos profesor. ">
        <span>Profesores Activos</span>
      </Tooltip>
    ),
    dataIndex: "active_teachers",
    key: "active_teachers",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title=" Número de usuarios activos alumno.">
        <span>Alumnos Activos</span>
      </Tooltip>
    ),
    dataIndex: "active_pupils",
    key: "active_pupils",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de alumnos bloqueados.">
        <span>Alumnos Bloqueados</span>
      </Tooltip>
    ),
    dataIndex: "block_pupils",
    key: "block_pupils",
    width: 110,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de usuarios dados de baja alumno.">
        <span>Alumnos Baja</span>
      </Tooltip>
    ),
    dataIndex: "remove_pupils",
    key: "remove_pupils",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de accesos de usuario profesor.">
        <span>Accesos Profesores</span>
      </Tooltip>
    ),
    dataIndex: "access_teachers",
    key: "access_teachers",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de accesos de usuario alumno.">
        <span>Accesos Alumnos</span>
      </Tooltip>
    ),
    dataIndex: "access_pupils",
    key: "access_pupils",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de documentos subidos.">
        <span>DOCs Subidos</span>
      </Tooltip>
    ),
    dataIndex: "new_docs",
    key: "new_docs",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de audios subidos.">
        <span>Audios Subidos</span>
      </Tooltip>
    ),
    dataIndex: "new_audios",
    key: "new_audios",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de vídeos subidos.">
        <span>Videos Subidos</span>
      </Tooltip>
    ),
    dataIndex: "new_videos",
    key: "new_videos",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Nuevos comentarios añadidos.">
        <span>Comentarios</span>
      </Tooltip>
    ),
    dataIndex: "new_comments",
    key: "new_comments",
    width: 120,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de videoconferencias realizadas.">
        <span>Videoconferencias creadas</span>
      </Tooltip>
    ),
    dataIndex: "created_videocalls",
    key: "created_videocalls",
    width: 150,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Duracion de videoconferencias realizadas.">
        <span>Duracion de Videoconferencias</span>
      </Tooltip>
    ),
    dataIndex: "duration_videocalls",
    key: "duration_videocalls",
    width: 150,
    render: text =>
      text !== "0:00" ? (
        <StyledTag>{text}</StyledTag>
      ) : (
        <ZeroTag>{text}</ZeroTag>
      )
  },
  {
    title: (
      <Tooltip title="Número de participantes en videoconferencias.">
        <span>Participantes en Videoconferencias</span>
      </Tooltip>
    ),
    dataIndex: "attendance_videocalls",
    key: "attendance_videocalls",
    width: 150,
    render: (value, record) => {
      const totalParticipants = record.created_videocalls + value;
      return totalParticipants !== 0 ? (
        <StyledTag>{totalParticipants}</StyledTag>
      ) : (
        <ZeroTag>{totalParticipants}</ZeroTag>
      );
    }
  },
  {
    title: (
      <Tooltip title="Número de descargas que realizan los alumnos de archivos.">
        <span>DOCs Descargados</span>
      </Tooltip>
    ),
    dataIndex: "download_docs",
    key: "download_docs",
    width: 120,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de reproducciones de audios.">
        <span>Audios Reproducidos</span>
      </Tooltip>
    ),
    dataIndex: "played_audios",
    key: "played_audios",
    width: 120,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de reproducciones de vídeos.">
        <span>Videos Reproducidos</span>
      </Tooltip>
    ),
    dataIndex: "played_videos",
    key: "played_videos",
    width: 120,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Test Aprobados.">
        <span>Test Aprobados</span>
      </Tooltip>
    ),
    dataIndex: "pass_test",
    key: "pass_test",
    width: 120,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Test Suspendidos.">
        <span>Test Suspendidos</span>
      </Tooltip>
    ),
    dataIndex: "fail_test",
    key: "fail_test",
    width: 120,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Mensajes enviados.">
        <span>Mensajes</span>
      </Tooltip>
    ),
    dataIndex: "sent_messages",
    key: "sent_messages",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },

  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado el módulo de audios.">
        <span>Mod. Audios</span>
      </Tooltip>
    ),
    dataIndex: "module_audios",
    key: "module_audios",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },

  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado el módulo de vídeos. ">
        <span>Mod. Videos</span>
      </Tooltip>
    ),
    dataIndex: "modules_videos",
    key: "modules_videos",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Compañías dadas de alta">
        <span>Nuevos Clientes</span>
      </Tooltip>
    ),
    dataIndex: "total_companies",
    key: "total_companies",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Compañías dadas de baja">
        <span>Bajas Clientes</span>
      </Tooltip>
    ),
    dataIndex: "removed_companies",
    key: "removed_companies",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número total de compañías activas.">
        <span>Clientes Activos</span>
      </Tooltip>
    ),
    dataIndex: "new_companies",
    key: "new_companies",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },

  {
    title: (
      <Tooltip title="Demos solicitadas">
        <span>Demos</span>
      </Tooltip>
    ),
    dataIndex: "new_demos",
    key: "new_demos",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Dossier solicitados">
        <span>Dossier</span>
      </Tooltip>
    ),
    dataIndex: "request_dossier",
    key: "request_dossier",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Contactos recibidos en la landing">
        <span>Contactos</span>
      </Tooltip>
    ),
    dataIndex: "request_contacts",
    key: "request_contacts",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Contactos de feedback recibidos en la landing">
        <span>Feedback</span>
      </Tooltip>
    ),
    dataIndex: "request_feedback",
    key: "request_feedback",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>500MB</span>
      </Tooltip>
    ),
    dataIndex: "storage_500",
    key: "storage_500",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>2GB</span>
      </Tooltip>
    ),
    dataIndex: "storage_2000",
    key: "storage_2000",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>5GB</span>
      </Tooltip>
    ),
    dataIndex: "storage_5000",
    key: "storage_5000",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>10GB</span>
      </Tooltip>
    ),
    dataIndex: "storage_10000",
    key: "storage_10000",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },

  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>15GB</span>
      </Tooltip>
    ),
    dataIndex: "storage_15000",
    key: "storage_15000",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>30GB</span>
      </Tooltip>
    ),
    dataIndex: "storage_30000",
    key: "storage_30000",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>60GB</span>
      </Tooltip>
    ),
    dataIndex: "storage_60000",
    key: "storage_60000",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>120GB</span>
      </Tooltip>
    ),
    dataIndex: "storage_120000",
    key: "storage_120000",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>150GB</span>
      </Tooltip>
    ),
    dataIndex: "storage_150000",
    key: "storage_150000",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>200GB</span>
      </Tooltip>
    ),
    dataIndex: "storage_200000",
    key: "storage_200000",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },
  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>250GB</span>
      </Tooltip>
    ),
    dataIndex: "storage_250000",
    key: "storage_250000",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },

  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>300GB</span>
      </Tooltip>
    ),
    dataIndex: "storage_300000",
    key: "storage_300000",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  },

  {
    title: (
      <Tooltip title="Número de compañías que tienen contratado este almacenaje.">
        <span>350GB</span>
      </Tooltip>
    ),
    dataIndex: "storage_350000",
    key: "storage_350000",
    width: 100,
    render: text =>
      text !== 0 ? <StyledTag>{text}</StyledTag> : <ZeroTag>{text}</ZeroTag>
  }
];

export const Statistic = props => {
  const date = new Date();
  const actualYear = date.getFullYear();
  const [totalActiveUser, setTotalActiveUser] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [actualMonthDay, setActualMonthDay] = useState({
    month: date.getMonth(),
    days: new Date(actualYear, date.getMonth(), 0).getDate()
  });

  const valuesByDay = (data, selectedDay, field = "creation_date") => {
    const matches = data.filter(day => {
      const dateDay = new Date(day[field]);

      return dateDay.getDate() === selectedDay;
    });

    return matches;
  };

  const filterByDay = (data, selectedDay, field = "registration") => {
    const matches = data.filter(day => {
      const dateDay = new Date(day[field]);

      return dateDay.getDate() === selectedDay;
    });

    return matches.length;
  };

  const filterByDayUsers = (data, selectedDay) => {
    const matches = data.filter(day => {
      const dateDay = new Date(day.registration);

      return dateDay.getDate() === selectedDay && day.users > 0;
    });
    return matches.reduce((acc, match) => acc + match.users, 0);
  };

  const parserDocs = (files, name = "file_name") => {
    const values = {
      docs: [],
      audios: [],
      videos: []
    };
    values.docs = files.filter(file => {
      const extension = getExtension(file[name]);
      return extension !== "mp3" && extension !== "mp4" && extension !== "m4a";
    });
    values.audios = files.filter(file => {
      const extension = getExtension(file[name]);
      return extension === "mp3" || extension === "m4a";
    });
    values.videos = files.filter(file => {
      const extension = getExtension(file[name]);
      return extension === "mp4";
    });
    return values;
  };

  const parserTests = tests => {
    const values = {
      passed: [],
      failed: []
    };
    values.passed = tests.filter(
      test => test.value.indexOf("Enhorabuena") > -1
    );
    values.failed = tests.filter(test => test.value.indexOf("Ánimo") > -1);
    return values;
  };

  const parserModules = modules => {
    const values = {
      audios: [],
      videos: []
    };
    const enabledModules = modules.filter(module => module.status === 1);
    values.videos = enabledModules.filter(
      module => module.module.type === "video"
    );
    values.audios = enabledModules.filter(
      module => module.module.type === "audio"
    );

    return values;
  };

  const parserContacts = data => {
    const values = {
      demos: [],
      contacts: [],
      dossier: []
    };
    values.demos = data.filter(value => value.source === "demo");
    values.contacts = data.filter(value => value.source === "contacto");
    values.dossier = data.filter(value => value.source === "dossier");
    return values;
  };

  const fillData = data => {
    const maxActiveCompanies = data.maxActiveCompanies.length;

    const values = [];
    const totals = {
      day: "Total",
      new_teachers: 0,
      new_pupils: 0,
      active_teachers: 0,
      active_pupils: 0,
      block_pupils: 0,
      remove_pupils: 0,
      access_teachers: 0,
      access_pupils: 0,
      created_videocalls: 0,
      duration_videocalls: 0,
      attendance_videocalls: 0,
      new_docs: 0,
      new_audios: 0,
      new_videos: 0,
      new_comments: 0,
      download_docs: 0,
      played_audios: 0,
      played_videos: 0,
      pass_test: 0,
      fail_test: 0,
      sent_messages: 0,
      total_companies: 0,
      removed_companies: 0,
      module_audios: 0,
      modules_videos: 0,
      new_companies: maxActiveCompanies,
      new_demos: 0,
      request_contacts: 0,
      request_dossier: 0,
      request_feedback: 0,
      storage_500: data.actualStorages.storage500mb,
      storage_2000: data.actualStorages.storage2gb,
      storage_5000: data.actualStorages.storage5gb,
      storage_10000: data.actualStorages.storage10gb,
      storage_15000: data.actualStorages.storage15gb,
      storage_30000: data.actualStorages.storage30gb,
      storage_60000: data.actualStorages.storage60gb,
      storage_120000: data.actualStorages.storage120gb,
      storage_150000: data.actualStorages.storage150gb,
      storage_200000: data.actualStorages.storage200gb,
      storage_250000: data.actualStorages.storage250gb,
      storage_300000: data.actualStorages.storage300gb,
      storage_350000: data.actualStorages.storage350gb
    };
    let maxNumCompanies = maxActiveCompanies;
    Array.from(new Array(actualMonthDay.days)).map((item, index) => {
      const valNewTeachers = filterByDay(data.newTeachers, index + 1);
      const valNewPupils = filterByDay(data.newPupils, index + 1);

      const valActiveTeachers = filterByDayUsers(
        data.activeTeachers,
        index + 1
      );

      const valActivePupils = filterByDayUsers(data.activePupils, index + 1);
      const valBlockedPupils = filterByDayUsers(data.blockedPupils, index + 1);
      const valRemovedPupils = filterByDay(
        data.removedPupils,
        index + 1,
        "removal"
      );

      const valAccessTeachers = filterByDay(
        data.accessesTeacher,
        index + 1,
        "date"
      );
      const valAccessPupils = filterByDay(
        data.accessesPupil,
        index + 1,
        "date"
      );
      const valAccessVideocalls = filterByDay(
        data.createdVideocalls,
        index + 1,
        "creation_date"
      );

      const valuesDuration = valuesByDay(data.createdVideocalls, index + 1);

      const valDurationVideocalls = fancyTimeFormat(
        valuesDuration
          .map(val => val.duration)
          .reduce((acc, val) => acc + val, 0)
      );

      const valAttendancesVideocalls = filterByDay(
        data.attendacesVideocalls,
        index + 1,
        "date"
      );

      const parsedDocsAudiosVideos = parserDocs(data.newDocs);
      const valNewDocs = filterByDay(
        parsedDocsAudiosVideos.docs,
        index + 1,
        "updated_at"
      );
      const valNewAudios = filterByDay(
        parsedDocsAudiosVideos.audios,
        index + 1,
        "updated_at"
      );
      const valNewVideos = filterByDay(
        parsedDocsAudiosVideos.videos,
        index + 1,
        "updated_at"
      );
      const parsedDownloadedDocsAudiosVideos = parserDocs(
        data.downloadedDocs,
        "filename"
      );
      const valDownloadedDocs = filterByDay(
        parsedDownloadedDocsAudiosVideos.docs,
        index + 1,
        "date"
      );
      const valDownloadedAudios = filterByDay(
        parsedDownloadedDocsAudiosVideos.audios,
        index + 1,
        "date"
      );
      const valDownloadedVideos = filterByDay(
        parsedDownloadedDocsAudiosVideos.videos,
        index + 1,
        "date"
      );

      const valNewComments = filterByDay(
        data.newComments,
        index + 1,
        "update_datetime"
      );

      const parsedMadeTests = parserTests(data.madeTests);
      const valPassTests = filterByDay(
        parsedMadeTests.passed,
        index + 1,
        "date"
      );
      const valFailTests = filterByDay(
        parsedMadeTests.failed,
        index + 1,
        "date"
      );
      const valSentMessages = filterByDay(
        data.sentMessages,
        index + 1,
        "creation"
      );

      const valTotalCompanies = filterByDay(
        data.activeCompanies,
        index + 1,
        "activation_date"
      );

      const valRemovedCompanies = filterByDay(
        data.removedCompanies,
        index + 1,
        "date"
      );

      const parsedModules = parserModules(data.modules);

      const valModuleAudios = filterByDay(
        parsedModules.audios,
        index + 1,
        "date"
      );
      const valModuleVideos = filterByDay(
        parsedModules.videos,
        index + 1,
        "date"
      );
      let valNewCompanies = maxActiveCompanies;

      if (index === 0) {
        valNewCompanies =
          maxActiveCompanies + valTotalCompanies - valRemovedCompanies;
        maxNumCompanies = valNewCompanies;
      } else {
        valNewCompanies =
          values[index - 1].new_companies +
          valTotalCompanies -
          valRemovedCompanies;

        maxNumCompanies = valNewCompanies;
      }

      const parsedContacts = parserContacts(data.contacts);

      const valNewDemos = filterByDay(
        parsedContacts.demos,
        index + 1,
        "receive_date"
      );

      const valRequestContacts = filterByDay(
        parsedContacts.contacts,
        index + 1,
        "receive_date"
      );

      const valRequestDossier = filterByDay(
        parsedContacts.dossier,
        index + 1,
        "receive_date"
      );

      const valRequestFeedback = filterByDay(data.feedbacks, index + 1, "date");

      values.push({
        day: index + 1,
        new_teachers: valNewTeachers,
        new_pupils: valNewPupils,
        active_teachers: valActiveTeachers,
        active_pupils: valActivePupils,
        block_pupils: valBlockedPupils,
        remove_pupils: valRemovedPupils,
        access_teachers: valAccessTeachers,
        access_pupils: valAccessPupils,
        created_videocalls: valAccessVideocalls,
        duration_videocalls: valDurationVideocalls,
        attendance_videocalls: valAttendancesVideocalls,
        new_docs: valNewDocs,
        new_audios: valNewAudios,
        new_videos: valNewVideos,
        new_comments: valNewComments,
        download_docs: valDownloadedDocs,
        played_audios: valDownloadedAudios,
        played_videos: valDownloadedVideos,
        pass_test: valPassTests,
        fail_test: valFailTests,
        sent_messages: valSentMessages,
        total_companies: valTotalCompanies,
        removed_companies: valRemovedCompanies,
        module_audios: valModuleAudios,
        modules_videos: valModuleVideos,
        new_companies: valNewCompanies,
        new_demos: valNewDemos,
        request_contacts: valRequestContacts,
        request_dossier: valRequestDossier,
        request_feedback: valRequestFeedback,
        storage_500: 0,
        storage_2000: 0,
        storage_5000: 0,
        storage_10000: 0,
        storage_15000: 0,
        storage_30000: 0,
        storage_60000: 0,
        storage_120000: 0,
        storage_150000: 0,
        storage_200000: 0,
        storage_250000: 0,
        storage_300000: 0,
        storage_350000: 0
      });
      totals.new_teachers += valNewTeachers;
      totals.new_pupils += valNewPupils;
      totals.active_teachers += valActiveTeachers;
      totals.active_pupils += valActivePupils;
      totals.block_pupils += valBlockedPupils;
      totals.remove_pupils += valRemovedPupils;
      totals.access_teachers += valAccessTeachers;
      totals.created_videocalls += valAccessVideocalls;
      let seconds = 0;
      if (valDurationVideocalls !== "0" && valDurationVideocalls !== "0:00") {
        const splitted = valDurationVideocalls.split(":");
        if (splitted.length === 2) {
          seconds = +splitted[0] * 60 + +splitted[1];
        } else if (splitted.length === 3) {
          seconds = +splitted[0] * 60 * 60 + +splitted[1] * 60 + +splitted[2];
        }
      }

      totals.duration_videocalls += seconds;

      totals.attendance_videocalls += valAttendancesVideocalls;
      totals.access_pupils += valAccessPupils;
      totals.new_docs += valNewDocs;
      totals.new_audios += valNewAudios;
      totals.new_videos += valNewVideos;
      totals.new_comments += valNewComments;
      totals.download_docs += valDownloadedDocs;
      totals.played_audios += valDownloadedAudios;
      totals.played_videos += valDownloadedVideos;
      totals.pass_test += valPassTests;
      totals.fail_test += valFailTests;
      totals.sent_messages += valSentMessages;
      totals.total_companies += valTotalCompanies;
      totals.removed_companies += valRemovedCompanies;
      totals.module_audios += valModuleAudios;
      totals.modules_videos += valModuleVideos;
      totals.new_demos += valNewDemos;
      totals.request_contacts += valRequestContacts;
      totals.request_dossier += valRequestDossier;
      totals.request_feedback += valRequestFeedback;
    });
    totals.new_companies = maxNumCompanies;
    const t = totals.duration_videocalls;
    const d = Math.floor(t / 86400),
      h = ("0" + (Math.floor(t / 3600) % 24)).slice(-2),
      m = ("0" + (Math.floor(t / 60) % 60)).slice(-2),
      s = ("0" + (t % 60)).slice(-2);
    const day = d > 0 ? d + "d " : "";
    const hour = parseInt(h) > 0 ? h + ":" : "";
    const minutes = parseInt(m) > 0 ? m + ":" : "";
    const seconds = t > 60 ? s : s + "s";

    totals.duration_videocalls = day + hour + minutes + seconds;
    values.push(totals);
    setDataSource(values);
    setTotalActiveUser(data.totalPupilsActive);
  };

  const getStatistic = month => {
    setDataSource([]);
    setActualMonthDay({
      month,
      days: new Date(actualYear, month + 1, 0).getDate()
    });
  };

  useEffect(() => {
    apiClient({
      path: `super/statistics?month=${actualMonthDay.month}`,
      method: "GET"
    })
      .then(json => {
        fillData(json);
      })
      .catch(err => {
        if (err.message === "Se ha caducado la sesion") {
          localStorage.removeItem("token");
          props.history.push("/");
        }
      });
  }, [actualMonthDay]);

  return (
    <Wrapper>
      <Row type="flex" justify="space-between">
        <Col>
          <Title style={{ fontWeight: "bold" }}> Estadísticas</Title>
          Total usuarios activos HOY: {totalActiveUser}
        </Col>
        <Col>
          Selecciona un mes:
          <Select
            size="large"
            onChange={value => getStatistic(value)}
            defaultValue={date.getMonth()}
            style={{ marginLeft: "5px", width: "200px" }}
          >
            <Option value={0}>Enero</Option>
            <Option value={1}>Febrero</Option>
            <Option value={2}>Marzo</Option>
            <Option value={3}>Abril</Option>
            <Option value={4}>Mayo</Option>
            <Option value={5}>Junio</Option>
            <Option value={6}>Julio</Option>
            <Option value={7}>Agosto</Option>
            <Option value={8}>Septiembre</Option>
            <Option value={9}>Octubre</Option>
            <Option value={10}>Noviembre</Option>
            <Option value={11}>Diciembre</Option>
          </Select>
        </Col>
      </Row>
      <Divider style={{ marginTop: "0" }} />
      <Table
        loading={dataSource.length === 0}
        size="small"
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ y: "65vh", x: 2500 }}
      />
    </Wrapper>
  );
};
