import React from "react";
import { Form, Icon, Input, Button } from "antd";
import { Redirect } from "react-router-dom";

import styled from "styled-components";

import svgString from "assets/background.svg";

const WrapperForm = styled.div`
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
  max-width: 300px;
  display: flex;
`;
const ImgStyled = styled.img`
  background: #f0f2f5;
  src: ${props => props.src};
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

const Title = styled.h1``;

const LoginForm = ({ form, onClearSession, onLogin, isLogin }) => {
  if (isLogin && !localStorage.getItem("token")) {
    onClearSession();
  }
  if (isLogin && localStorage.getItem("token")) {
    return <Redirect from="login" to="dashboard/estadisticas" />;
  }

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onLogin(values);
      }
    });
  };
  const { getFieldDecorator } = form;
  return (
    <WrapperForm>
      <ImgStyled src={svgString} />

      <Form onSubmit={handleSubmit} className="login-form">
        <Form.Item>
          <Title>Inicia Sesión</Title>
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("_username", {
            rules: [{ required: true, message: "Please input your username!" }]
          })(
            <Input
              size="large"
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Username"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("_password", {
            rules: [{ required: true, message: "Please input your Password!" }]
          })(
            <Input
              size="large"
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            style={{ width: "100%" }}
            icon="login"
            type="primary"
            htmlType="submit"
            className="login-form-button"
            size="large"
          >
            Acceder
          </Button>
        </Form.Item>
      </Form>
    </WrapperForm>
  );
};

export const Login = Form.create({ name: "login" })(LoginForm);
