import React, { useEffect, useState } from "react";
import NewTable from "components/NewTable";

import styled from "styled-components";
import ContainerTitle from "components/ContainerTitle";
import { formatDateES, parseTime } from "utils/helpers";

const Delete = styled.div``;

const Wrapper = styled.div`
  padding: 1rem 1rem 0.5rem 1rem;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
  background-color: #ffffff;
  box-shadow: "0px 0px 12px -3px #ccc";
  max-width: 1800px;
`;

export const UsersList = ({ getUsers, users, results, history }) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    getUsers(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getUsers(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 100
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Tipo de usuario",
      dataIndex: "roles",
      key: "roles",
      render: text => {
        return text[0];
      }
    },
    {
      title: "Ultimo acceso",
      dataIndex: "last_access",
      key: "last_access",
      render: date => {
        return formatDateES(new Date(date)) + " - " + parseTime(new Date(date));
      }
    }
  ];
  return (
    <Wrapper>
      <NewTable
        tableTitle="Listado de Accesos de Usuarios"
        tableSubTitle=""
        buttonsHeader={[]}
        header={""}
        iconTitle={null}
        columns={columns}
        data={users}
        pagination={{
          current: page,
          total: results,
          hideOnSinglePage: true
        }}
        onChange={pagination => setPage(pagination.current)}
      />
    </Wrapper>
  );
};
