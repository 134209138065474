import { connect } from "react-redux";

import { Company as View } from "views/Company";
import {
  getCompany,
  setCompanyData,
  addNewAdmin
} from "core/Companies/actions";

const mapStateToProps = state => ({
  data: state.companies.company
});

const mapDispatchToProps = dispatch => ({
  getCompany: id => dispatch(getCompany(id)),
  setCompanyData: (data, id) => dispatch(setCompanyData(data, id)),
  addNewAdmin: (data, id) => dispatch(addNewAdmin(data, id))
});

export const Company = connect(
  mapStateToProps,
  mapDispatchToProps
)(View);
