import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import { PrivateRoute } from "utils";

import PrivateLayout from "layouts/Private";
import PublicLayout from "layouts/Public";

const hist = createBrowserHistory();

const ProviderRouter = ({ isLogin, isAdmin }) => {
  return (
    <Router history={hist} basename={"/admin"}>
      <Switch>
        <PrivateRoute
          path="/dashboard"
          component={PrivateLayout}
          isLogin={isLogin}
          isAdmin={isAdmin}
        />
        <Route path="/" component={PublicLayout} />
      </Switch>
    </Router>
  );
};

ProviderRouter.propTypes = {
  isLogin: PropTypes.bool,
  isAdmin: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    auth: { isLogin, isAdmin }
  } = state;
  return { isLogin, isAdmin };
};

export default connect(mapStateToProps)(ProviderRouter);
