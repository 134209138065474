import { connect } from "react-redux";

import { ActionsUsers as View } from "views/ActionsUsers";
import { getActionsUsers } from "core/User/actions";

const mapStateToProps = state => ({
  users: state.user.users,
  results: state.user.results
});

const mapDispatchToProps = dispatch => ({
  getActionsUsers: page => dispatch(getActionsUsers(page))
});

export const ActionsUsers = connect(mapStateToProps, mapDispatchToProps)(View);
