import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { Spin, Row, Col, Button, Icon, Alert, Switch, Table } from "antd";
import ContainerTitle from "components/ContainerTitle";

import { showNotification } from "utils/utilities";
import apiClient from "utils";
import { formatDateES, parseTime } from "utils/helpers";

const Wrapper = styled.div`
  padding: 1rem 1rem 0.5rem 1rem;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
  background-color: #ffffff;
  box-shadow: "0px 0px 12px -3px #ccc";
  max-width: 1800px;
`;

const Analytics = ({ ...props }) => {
  const [state, setState] = useState({
    page: 1,
    paginator: {
      maxPages: 0,
      results: 0,
      paginator: []
    }
  });
  const getData = page =>
    apiClient({
      path: `super/analitycs/${page}`,
      method: "GET"
    })
      .then(json => {
        setState({
          page,
          paginator: {
            maxPages: json.data.maxPages,
            paginator: json.data.paginator,
            results: json.data.results
          }
        });
      })
      .catch(err => showNotification("Error", err.message, "error"));
  useEffect(() => {
    getData(1);
  }, []);

  const onChangePage = page => {
    getData(page);
  };

  const columns = [
    {
      title: "Fecha Busqueda",
      dataIndex: "search_date",
      key: "search_date",
      render: (text, record) => {
        return `${formatDateES(new Date(text))} - ${parseTime(new Date(text))}`;
      },
      width: 140
    },
    {
      title: "Oposicion",
      dataIndex: "competition",
      key: "competition",
      render: text => {
        return text && text.name;
      },
      width: 200
    },
    {
      title: "Tipo preparacion",
      dataIndex: "type_preparation",
      key: "type_preparation",
      render: text => {
        return text && text.name;
      },
      width: 200
    },
    {
      title: "Ciudad",
      dataIndex: "provinces",
      key: "provinces",
      render: text => {
        return text && text.name;
      },
      width: 200
    },
    {
      title: "Localidad",
      dataIndex: "town",
      key: "town",
      render: text => {
        return text && text.name;
      },
      width: 200
    },
    {
      title: "Titulacion",
      dataIndex: "degree",
      key: "degree",
      render: text => {
        return text && text.name;
      },
      width: 200
    },
    {
      title: "Especialidad",
      dataIndex: "speciality",
      key: "speciality",
      render: text => {
        return text && text.name;
      },
      width: 200
    },
    {
      title: "Experiencia",
      dataIndex: "experience",
      key: "experience",
      render: text => `Desde ${text}`,
      width: 200
    },
    {
      title: "Horario",
      dataIndex: "schedule",
      key: "schedule",
      render: text => {
        switch (text) {
          case "1":
            return "De mañana";
          case "2":
            return "De tarde";
          case "3":
            return "De noche";
        }
      },
      width: 100
    },
    {
      title: "Tipo de preparador",
      dataIndex: "type_teacher",
      key: "type_teacher",
      render: text => {
        switch (text) {
          case "1":
            return "Academia";
          case "2":
            return "Preparador";
        }
      },
      width: 120
    },
    {
      title: "Precio Min",
      dataIndex: "min_price",
      key: "min_price",
      align: "right",
      width: 100
    },
    {
      title: "Precio Max",
      dataIndex: "max_price",
      key: "max_price",
      align: "right",
      width: 120
    }
  ];
  return (
    <Wrapper>
      <ContainerTitle
        title="Analiticas sobre busquedas en marketplace"
        buttons={[]}
      >
        <Table
          scroll={{ x: "100%" }}
          rowKey={record => record.uid}
          columns={columns}
          dataSource={state.paginator.paginator}
          pagination={{
            current: state.page,
            total: state.paginator.results,
            pageSize: 30,
            hideOnSinglePage: true
          }}
          onChange={pagination => {
            onChangePage(pagination.current);
          }}
        />
      </ContainerTitle>
    </Wrapper>
  );
};

export default Analytics;
