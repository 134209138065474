const theme = {
  font: {
    // fontFamily: "Inter",
    fontFamily: "Nunito Sans",
    fontSize: "16px"
  },
  colors: {
    light: "#FFFFFF",
    dark: "#000000",
    primary: "#3561A3",
    secondary: "#5C6C78",
    terciary: "#F5F6F8",
    links: "#000000",
    danger: "#F86861",
    border: "#dbe2ea",
    warning: "#F7B349",
    success: "#7CDD81",
    selected: "#BBBBBB",
    hover: "#DDEEF6",
    gray: "#E8E9EE",
    borderGray: "#D7DEE6"
  }
};

export default theme;
