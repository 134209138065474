import * as actions from "./actions";

export const initialState = {
  list: [],
  advertiser: {},
  results: 0
};

const reducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case actions.ADVERTISERS_GET:
      return {
        list: action.payload,
        results: action.results
      };
    case actions.ADVERTISER_GET:
      return {
        advertiser: action.payload
      };
    default:
      return state;
  }
};

export { actions };
export default reducer;
